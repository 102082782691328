import React, { useState, useEffect, useRef } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
//Styles
import "./styles.css";

export const DisponibilitySmall = () => {
  const experience = useSelector((state) => state.aplicationConfig.productActive);
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState("600px");
  console.log("DisponibilitySmall");
  console.log(experience);
  console.log(experience.experienceId);
  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const handleModal = () => {
    console.log("handleModal");
    setOpenModal(!openModal);
  };

  const experienceTicandoPath = () => {
    const urlBase = "https://dev.crea-ticket.com/civitrip/";
    const calendarParams = "/stretch/";
    console.log(urlBase + experience.publicApiToken + calendarParams + experience.experienceId);
    return urlBase + experience.publicApiToken + calendarParams + experience.experienceId;
  };

  const ContentIframe = () => {
    return (
      <iframe
        id="iframeDisponibility"
        ref={iframeRef}
        scrolling="no"
        className="embebbedIframe"
        src={experienceTicandoPath()}
        title="Carrito"
        height={iframeHeight}
      ></iframe>
    );
  };

  useEffect(() => {
    const handleMessage = (e) => {
      if (e.origin === "http://localhost:3006" || e.origin === "https://dev.crea-ticket.com") {
        switch (e.data) {
          case "cartAdded":
            cartAdded();
            break;

          default:
            result(e.data);
        }
        cartAdded();
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  const cartAdded = () => {
    console.log("añadido a carrito");
  };

  const result = (result) => {
    console.log("recibido: ", result);
    try {
      const data = JSON.parse(result);
      if (data.hasOwnProperty("height")) {
        console.log("cambiando la altura: ", data.height);
        const iframe = document.getElementById("iframeDisponibility");
        iframe.style.height = data.height + "px";
        //setIframeHeight(data.height);
      }
      if (data.hasOwnProperty("cartAdded")) {
        cartAdded();
      }
    } catch (e) {}
    // console.log('height recibido: ', JSON.parse(height))
  };

  return (
    <>
      <ContentIframe />
    </>
  );
};
