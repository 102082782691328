import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./style.css";

export const ModalComponent = ({ openModal, handleClose, content, customStyles = null, header = null, footer = null }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px",
    borderRadius: "4px",
    boxShadow: 20,
    p: 1,
  };

  const styleBody = {
    width: customStyles && customStyles.width ? customStyles.width : 800,
    height: customStyles && customStyles.height ? customStyles.height : 600,
    /*overflowY: "auto",*/
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          {header !== null && <div>{header}</div>}
          <div className="modalBox" /*style={styleBody}*/>{content}</div>
          {footer !== null && <div>{footer}</div>}
        </Box>
      </Modal>
    </div>
  );
};
