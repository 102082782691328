import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children, userConfig, page }) => {
  console.log("ProtectedRoute");
  console.log(userConfig);
  console.log(page);
  if (userConfig.logged === undefined || userConfig.userData === undefined) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  if (page === "proveedores")
    if (!userConfig.logged || userConfig.userData.rol !== "proveedor") {
      // user is not authenticated
      return <Navigate to="/login" />;
    }

  if (page === "administracion")
    if (!userConfig.logged || userConfig.userData.rol !== "administrador") {
      // user is not authenticated
      return <Navigate to="/login" />;
    }

  return children;
};
