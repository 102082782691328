const apiRestUrl = "https://api.civitrip.com/api/";

const apiCall = (urlRequest) => {
  const url = apiRestUrl + urlRequest;
  return fetch(url)
    .then((res) => {
      return res.json();
      //return res;
    })
    .catch(function (error) {
      console.error("[API Rest Error]" + error.message);
    });
};

export const getCountriesStates = () => {
  return apiCall("countriesStates");
};

export const getCountries = () => {
  return apiCall("countries");
};

export const getCities = () => {
  return apiCall("getCities");
};

export const getCountriesCities = () => {
  return apiCall("getCountriesCities");
};

export const getCountriesStatesCities = () => {
  return apiCall("getCountriesStatesCities");
};

export const getStates = () => {
  return apiCall("getStates");
};

export const getStatesCities = () => {
  return apiCall("getStatesCities");
};
