import { Calendar } from "./../../../../components/calendar/weekly";
export const Bookings = () => {
  return (
    <>
      <div>
        <Calendar />
      </div>
    </>
  );
};
