import React, { useState } from "react";
//Reducers
import { useSelector, useDispatch } from "react-redux";
import { setUserData, setAlert } from "../../../../reducers/userConfigSlice";
//Components
import { Input, Select } from "../../../../components/forms";
import { FiSave } from "react-icons/fi";
//API
import { updateClient } from "../../../../api/requests";

export const Bussiness = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);
  const [saveChanges, setSaveChanges] = useState("");

  const updateData = async () => {
    let data = {
      estadolegal: document.getElementById("register_estadolegal").value,
      dnicif: document.getElementById("register_dnicif").value,
      ciudad: document.getElementById("register_ciudad").value,
      cp: document.getElementById("register_cp").value,
      region: document.getElementById("register_region").value,
      pais: document.getElementById("register_pais").value,
      moneda: document.getElementById("register_moneda").value,
      activeSection: "bussiness",
      id: userData.id,
    };

    const response = await updateClient(data);
    console.log(response);
    if (response) {
      //dispatch(setUserData(response[0]));
      dispatch(setAlert(1));
    }
  };

  return (
    <>
      <h2>Empresa</h2>
      <div className="section">
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => updateData()}>
          <FiSave />
        </button>
        <label style={{ color: "#0140de", fontSize: "12px", float: "right", padding: "5px" }}>{saveChanges}</label>
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div className="sub-section-2">
            <Select
              label="Estado legal"
              id="register_estadolegal"
              data={[
                { id: 1, value: "Empresa" },
                { id: 2, value: "Autónomo" },
              ]}
            />
          </div>
          <div className="sub-section-2">
            <Input label="CIF/DNI" id="register_dnicif" suggestions={[]} explanation={""} handleInput={null} />
          </div>
        </div>

        <div style={{ display: "inline-flex", width: "100%" }}>
          <div className="sub-section-2">
            <Input label="Ciudad" id="register_ciudad" suggestions={[]} explanation={""} handleInput={null} value={userData.ciudad} />
          </div>
          <div className="sub-section-2">
            <Input label="Código postal" id="register_cp" suggestions={[]} explanation={""} handleInput={null} value={userData.cp} />
          </div>
        </div>

        <Input label="Región" id="register_region" suggestions={[]} explanation={""} handleInput={null} value={userData.region} />
        <Select
          label="Pais"
          id="register_pais"
          data={[
            { id: 1, value: "España" },
            { id: 2, value: "Francia" },
          ]}
          selected={userData.pais}
        />
        <Select
          label="Moneda"
          id="register_moneda"
          data={[
            { id: 1, value: "Euro" },
            { id: 2, value: "Dolar" },
          ]}
          selected={userData.moneda}
        />
      </div>
    </>
  );
};
