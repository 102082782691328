//Datatable
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const Reservas = ({ webConfiguration }) => {
  return (
    <>
      <h2>Reservas</h2>
      <div className="section" style={{ display: "inline-flex", width: "97%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Destino</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Descarga</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webConfiguration &&
                webConfiguration.proveedores &&
                webConfiguration.proveedores.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
