import React from "react";
//Reducers
import { setAdmExperienceSelected, setAlert } from "../../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import { Input, Select, OptionsGroup, InputWOptions, InputWOptionsV2 } from "../../../../../components/forms";
import { FiSave, FiCornerUpLeft } from "react-icons/fi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//API
import { updateProveedor } from "../../../../../api/requests";
import { GoogleMap } from "../../../../product/googleMap";
import { Galery } from "./selected/galery";
import { Address } from "./selected/address";

export const Selected = ({ countries, countriesStates, webConfiguration }) => {
  const dispatch = useDispatch();
  const experienceSelected = useSelector((state) => state.aplicationConfig.adm.experiences.selected);
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);

  const handleMapLocation = (e) => {
    console.log("handleMapLocation");
    console.log(e);
    dispatch(setAdmExperienceSelected({ ...experienceSelected, latitud: e.lat, longitud: e.lng }));
  };

  const updateData = async () => {
    let data = {
      shortdesc: document.getElementById("experience_shortdesc").value,
      largedesc: document.getElementById("experience_largedesc").value,
      photos: experienceSelected.photos.toString(),
      incluye: Array.prototype.slice
        .call(document.getElementsByClassName("class_experience_incluye"))
        .map((d) => d.innerHTML)
        .toString(),
      noincluye: Array.prototype.slice
        .call(document.getElementsByClassName("class_experience_noincluye"))
        .map((d) => d.innerHTML)
        .toString(),
      adiccional: Array.prototype.slice
        .call(document.getElementsByClassName("class_experience_adiccional"))
        .map((d) => d.innerHTML)
        .toString(),
      pais: document.getElementById("experience_pais").value,
      ciudad: document.getElementById("experience_ciudad").value,
      localidad: document.getElementById("experience_localidad").value,
      direccion: document.getElementById("experience_direccion").value,
      latitud: parseFloat(experienceSelected.latitud),
      longitud: parseFloat(experienceSelected.longitud),
      categorias: Array.prototype.slice
        .call(document.querySelectorAll("input[name=experience_categorias]:checked"))
        .map((el) => el.value)
        .toString(),
      tags: Array.prototype.slice
        .call(document.getElementsByClassName("class_experience_tags"))
        .map((d) => d.innerHTML)
        .toString(),
      experienceId: experienceSelected.experienceId,
      experienceName: experienceSelected.experienceName,
      experienceDescription: experienceSelected.experiencieDescription,
      activeSection: "experiences",
      id: userData.id,
      pathName: preparePathName(),
      experiencePrecioBase: document.getElementById("experience_precio_base").value,
      experienceHorario: Array.prototype.slice
        .call(document.querySelectorAll("input[name=experience_horario]:checked"))
        .map((el) => el.value)
        .toString(),
      experienceDuracion: document.getElementById("experience_duracion").value,
    };

    const response = await updateProveedor(data);
    if (response) {
      //savePreviousData();
      //dispatch(setProveedorConfig({ ...proveedorConfig, experiencias: response[0] }));
      setTimeout(() => {
        //TODO, es necesario? getProveedorConfig();
      }, 1500);
      console.log("setAlert 1");
      dispatch(setAlert(1));
    }
  };

  const preparePathName = () => {
    let path = experienceSelected.experienceName;
    path = path
      .toLowerCase()
      .replaceAll(" ", "")
      .normalize("NFD")
      .replaceAll(/[\u0300-\u036f]/g, "")
      .replaceAll("&", "-")
      .replaceAll(".", "-")
      .replaceAll("/", "-")
      .replaceAll(",", "-")
      .replaceAll("%", "-");
    return path;
  };

  const savePreviousData = () => {
    dispatch(
      setAdmExperienceSelected({
        ...experienceSelected,
        shortdesc: document.getElementById("experience_shortdesc").value,
        largedesc: document.getElementById("experience_largedesc").value,
        incluye: Array.prototype.slice
          .call(document.getElementsByClassName("class_experience_incluye"))
          .map((d) => d.innerHTML)
          .toString(),
        noincluye: Array.prototype.slice
          .call(document.getElementsByClassName("class_experience_noincluye"))
          .map((d) => d.innerHTML)
          .toString(),
        adiccional: Array.prototype.slice
          .call(document.getElementsByClassName("class_experience_adiccional"))
          .map((d) => d.innerHTML)
          .toString(),
        pais: document.getElementById("experience_pais").value,
        ciudad: document.getElementById("experience_ciudad").value,
        localidad: document.getElementById("experience_localidad").value,
        direccion: document.getElementById("experience_direccion").value,
        latitud: parseFloat(experienceSelected.latitud),
        longitud: parseFloat(experienceSelected.longitud),
        categorias: Array.prototype.slice
          .call(document.querySelectorAll("input[name=experience_categorias]:checked"))
          .map((el) => el.value)
          .toString(),
        tags: Array.prototype.slice
          .call(document.getElementsByClassName("class_experience_tags"))
          .map((d) => d.innerHTML)
          .toString(),
      })
    );
  };

  const handleSection = () => {
    dispatch(setAdmExperienceSelected(null));
  };

  return (
    experienceSelected !== null && (
      <>
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => updateData()}>
          <FiSave />
        </button>
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => handleSection()}>
          <FiCornerUpLeft />
        </button>

        <div>
          <b>{experienceSelected.experienceName}</b>
        </div>
        <div>
          <small>{experienceSelected.experienceDescription}</small>
        </div>
        <div>
          <hr style={{ margin: "20px 0", border: "1px #f4f4f4 solid" }} />
        </div>

        <Tabs defaultActiveKey="experience" id="tab-component" className="mb-3">
          <Tab eventKey="experience" title="Experiencia">
            <div className="row">
              <div className="col-4">
                <Galery />
              </div>
              <div className="col-8">
                {/* Descripcion corta */}
                <div style={{ paddingBottom: "15px" }}>
                  <label style={{ paddingBottom: "15px", fontWeight: "bold" }}>
                    Descripción corta <small>(Max. 70 carateres)</small>
                  </label>
                  <textarea id="experience_shortdesc" maxlength="500" height={5}>
                    {experienceSelected.shortdesc || ""}
                  </textarea>
                </div>

                {/* Descripcion larga */}
                <div>
                  <label style={{ paddingBottom: "15px", fontWeight: "bold" }}>
                    Descripción detallada <small>(Max. 500 carateres)</small>
                  </label>
                  <textarea id="experience_largedesc" rows="15">
                    {experienceSelected.largedesc || ""}
                  </textarea>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="detalles" title="Detalles">
            <h5 style={{ marginTop: "5px" }}>Información</h5>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div className="sub-section-2">
                <InputWOptionsV2
                  label="¿Qué incluye?"
                  id="experience_incluye"
                  suggestions={[]}
                  explanation={""}
                  selecteds={experienceSelected.incluye || ""}
                  textColor={"darkgreen"}
                />
              </div>
              <div className="sub-section-2">
                <InputWOptionsV2
                  label="¿Qué no incluye?"
                  id="experience_noincluye"
                  suggestions={[]}
                  explanation={""}
                  selecteds={experienceSelected.noincluye || ""}
                  textColor={"darkred"}
                />
              </div>
            </div>

            <h5 style={{ marginTop: "50px" }}>Información adicional</h5>
            <div>
              <InputWOptionsV2
                label="Información adiccional"
                id="experience_adiccional"
                suggestions={[]}
                explanation={""}
                selecteds={experienceSelected.adiccional || ""}
                textColor={"#000"}
              />
            </div>
          </Tab>

          <Tab eventKey="localizacion" title="Localización">
            <div className="row">
              <div className="col-4">
                <Address countries={countries} countriesStates={countriesStates} />
              </div>
              <div className="col-8">
                <h5 className="mb-4" style={{ marginTop: "5px" }}>
                  Localización
                </h5>
                <div style={{ width: "100%", height: "400px", backgroundColor: "lightgrey" }}>
                  <GoogleMap
                    lat={parseFloat(experienceSelected.latitud)}
                    lng={parseFloat(experienceSelected.longitud)}
                    callback={(e) => handleMapLocation(e)}
                  />
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="avanzado" title="Avanzado">
            <div className="row">
              <div className="col-4 mb-4">
                <h5>Categorías</h5>
                <div>
                  <OptionsGroup
                    label={"Categorías donde se incluye"}
                    name="experience_categorias"
                    options={webConfiguration.experiencias.map((category) => {
                      return { id: category.id, name: category.name };
                    })}
                    selecteds={experienceSelected.categorias || ""}
                  />
                </div>
              </div>
              <div className="col-4 mb-4">
                <h5>Etiquetas</h5>
                <div>
                  <InputWOptions
                    label="Tags que definan la experiencia"
                    id="experience_tags"
                    suggestions={[]}
                    explanation={"Agrega términos relacionados con la experiencia"}
                    selecteds={experienceSelected.tags || ""}
                  />
                </div>
              </div>

              <div className="col-4 mb-4">
                <h5>Precio base de la experiencia</h5>
                <div>
                  <Select
                    label={"Precio orientativo para mostrar en los resultados"}
                    name="experience_precio_base"
                    data={JSON.parse(experienceSelected.prices)
                      .filter((f) => f.name !== "")
                      .map((price) => {
                        return {
                          id: price.id,
                          value: `${price.passengerCategory.name} (${price.price.toFixed(2)} ${experienceSelected.currency})`,
                        };
                      })}
                    selecteds={""}
                    id="experience_precio_base"
                  />
                </div>
              </div>
              <div className="col-4 mb-4">
                <h5>Horario</h5>
                <div>
                  <OptionsGroup
                    label={"Horario de la experiencia"}
                    name="experience_horario"
                    options={[
                      { id: 1, name: "Día" },
                      { id: 2, name: "Tarde" },
                      { id: 3, name: "Noche" },
                    ]}
                    selecteds={""}
                    id="experience_horario"
                  />
                </div>
              </div>
              <div className="col-4 mb-4">
                <h5>Duración </h5>
                <div>
                  <Input
                    label="Duración en minutos"
                    id="experience_duracion"
                    suggestions={[]}
                    explanation={"Duración aproximada de la experiencia (Ej: 1 hora = 60)"}
                    handleInput={null}
                    value={""}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </>
    )
  );
};
