import React from "react";
//Redux
//Components
import { FiDownload, FiEye } from "react-icons/fi";
//API
//Styles
import "./style.css";

export const CardLarge = ({ e }) => {
  return (
    <div className="cardLarge">
      <div className="row">
        <div className="col-2">
          <div className="cardLargeImage" style={{ backgroundImage: "url(" + e.photos.split(",")[0] + ")" }}></div>
        </div>
        <div className="col-8">
          <div className="cardLargeBody">
            <div className="cardLargeTitle">{e.experienceName}</div>
            <div className="cardLargeSubTitle">14/03/2024 15:45</div>
            <div className="cardLargeSubTitle">
              <b style={{ color: "#3a66fb" }}>#VY9879866</b>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="cardLargeActions">
            <FiEye size={20} color={"#3a66fb"} style={{ marginRight: "15px" }} />
            <FiDownload size={20} color={"#3a66fb"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLarge;
