import "./style.css";

export const Table = ({ nameHeaders, values }) => {
  return (
    <div className="row customTable m-3">
      <div className="col-12">
        <div className="row tableHeader">
          {nameHeaders.map((header, index) => {
            return <div className={index === 0 ? "col-8" : "col"}>{header.name}</div>;
          })}
        </div>
        {values.map((value) => {
          return (
            <div className="row tableRow">
              {nameHeaders.map((header, index) => {
                return <div className={index === 0 ? "col-8" : "col"}>{value[header.key]}</div>;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
