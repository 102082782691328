import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../reducers/userConfigSlice";
//Components
import { FiCheckCircle } from "react-icons/fi";
//Styles
import "./style.css";

export const Alert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.aplicationConfig.adm.alert);
  const alerts = [
    { type: 0, text: "" },
    { type: 1, text: "Cambios guardados", color: "#2c32dc", icon: <FiCheckCircle /> },
  ];

  const Toast = () => {
    const data = alerts.filter((f) => f.type === alert)[0];
    return (
      <div className={"alert-msg"} style={{ borderLeft: `7px ${data.color} solid` }}>
        <span style={{ color: data.color }}>{data.icon}</span> {data.text}
      </div>
    );
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        dispatch(setAlert(0));
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return alert !== 0 && <Toast />;
};
