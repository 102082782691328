import React, { useState, useEffect } from "react";
//Components

import { Input, Select, OptionsGroup } from "./../../components/forms";
import { PaginationList } from "./../../components/paginationList";

import { FiSliders } from "react-icons/fi";
import { FiSave } from "react-icons/fi";
import "./style.css";
import { ModalComponent } from "../../components/modal";

export const ActivitiesFilters = ({ experiencias }) => {
  const [activeFilters, setActiveFilters] = useState(false);
  const [experiencesFiltered, setExperiencesFiltered] = useState(experiencias);
  const [filters, setFilters] = useState({ destinos: [], intereses: "", horario: "", duracion: "", idioma: "", precios: { min: 0, max: 0 } });
  const [orderBy, setOrderBy] = useState("Relevancia");

  const orderExperiences = (e) => {
    setOrderBy(e.target.value);
    updateExperiencesFiltered(filters, e.target.value);
  };

  const updateExperiencesFiltered = (filters, orderBy) => {
    //OrderBy
    let experiencesOrdered = experiencias;
    switch (orderBy) {
      case "Relevancia":
        experiencesOrdered = experiencias;
        break;
      case "PrecioMinMax":
        experiencesOrdered.sort((a, b) => a.defaultPrice - b.defaultPrice);
        break;
      case "PrecioMaxMin":
        experiencesOrdered.sort((a, b) => b.defaultPrice - a.defaultPrice);
        break;
      case "Valoracion":
        experiencesOrdered.sort((a, b) => a.valoracion - b.valoracion);
        break;
      default:
        break;
    }

    //Filter
    let experiencesWithFilters = experiencesOrdered;

    //Filtro destinos
    if (filters.destinos.length > 0)
      experiencesWithFilters = experiencesWithFilters.filter((f) => filters.destinos.includes(f.ciudad) && f.ciudad !== "");
    //Filtro intereses
    if (filters.intereses !== "")
      experiencesWithFilters = experiencesWithFilters.filter(
        (f) => f.tags !== "" && filters.intereses.split(",").some((element) => f.tags.split(",").includes(element))
      );
    //Filtro intereses
    if (filters.horario !== "")
      experiencesWithFilters = experiencesWithFilters.filter(
        (f) => f.horario !== "" && filters.horario.split(",").some((element) => f.horario.split(",").includes(element))
      );
    //Filtro duracion
    if (filters.duracion !== "") {
      let tramos = filters.duracion.split(",");
      let tramoDetalle = {
        tramo1Inicio: tramos[0] ? tramos[0].split("-")[0] : null,
        tramo1Fin: tramos[0] ? tramos[0].split("-")[1] : null,
        tramo2Inicio: tramos[1] ? tramos[1].split("-")[0] : null,
        tramo2Fin: tramos[1] ? tramos[1].split("-")[1] : null,
        tramo3Inicio: tramos[2] ? tramos[2].split("-")[0] : null,
        tramo3Fin: tramos[2] ? tramos[2].split("-")[1] : null,
        tramo4Inicio: tramos[3] ? tramos[3].split("-")[0] : null,
        tramo4Fin: tramos[3] ? tramos[3].split("-")[1] : null,
      };
      experiencesWithFilters = experiencesWithFilters.filter(
        (f) =>
          f.defaultHora !== 0 &&
          ((f.defaultHora >= tramoDetalle.tramo1Inicio && f.defaultHora <= tramoDetalle.tramo1Fin) ||
            (f.defaultHora >= tramoDetalle.tramo2Inicio && f.defaultHora <= tramoDetalle.tramo2Fin) ||
            (f.defaultHora >= tramoDetalle.tramo3Inicio && f.defaultHora <= tramoDetalle.tramo3Fin) ||
            (f.defaultHora >= tramoDetalle.tramo4Inicio && f.defaultHora <= tramoDetalle.tramo4Fin))
      );
    }

    //Filtro idioma
    if (filters.idioma !== "")
      experiencesWithFilters = experiencesWithFilters.filter(
        (f) => f.idioma !== "" && filters.idioma.split(",").some((element) => f.idioma.split(",").includes(element))
      );

    //Filtro precios
    if (filters.precios.min !== 0 && filters.precios.max !== 0) {
      experiencesWithFilters = experiencesOrdered.filter(
        (f) =>
          f.defaultPrice !== 0 &&
          parseInt(JSON.parse(f.prices).filter((f2) => f2.id === f.defaultPrice)[0]?.price) >= parseInt(filters.precios.min) &&
          parseInt(JSON.parse(f.prices).filter((f2) => f2.id === f.defaultPrice)[0]?.price) <= parseInt(filters.precios.max)
      );
    }

    setExperiencesFiltered(experiencesWithFilters);
    setActiveFilters(false);
  };

  const handleFilters = () => {
    let destinos = Array.prototype.slice
      .call(document.querySelectorAll("input[name=filters_destinos]:checked"))
      .map((el) => el.value)
      .toString();

    let intereses = Array.prototype.slice
      .call(document.querySelectorAll("input[name=filters_intereses]:checked"))
      .map((el) => el.value)
      .toString();

    let horario = Array.prototype.slice
      .call(document.querySelectorAll("input[name=filters_horario]:checked"))
      .map((el) => el.value)
      .toString();

    let duracion = Array.prototype.slice
      .call(document.querySelectorAll("input[name=filters_duracion]:checked"))
      .map((el) => el.value)
      .toString();

    let idioma = Array.prototype.slice
      .call(document.querySelectorAll("input[name=filters_idioma]:checked"))
      .map((el) => el.value)
      .toString();

    let precios = { min: document.getElementById("filters_precio_min").value, max: document.getElementById("filters_precio_max").value };
    const newFilters = {
      destinos: destinos === "" ? [] : destinos,
      intereses: intereses,
      horario: horario,
      duracion: duracion,
      idioma: idioma,
      precios: precios,
    };
    setFilters(newFilters);
    updateExperiencesFiltered(newFilters, orderBy);
  };

  const handleActiveFilters = () => {
    setActiveFilters(!activeFilters);
  };

  const destinosExperiencias = () => {
    let destinosUniq = [];
    experiencias.map((e, index) => {
      destinosUniq.filter((f) => f.name === e.ciudad).length === 0 && e.ciudad !== "" && destinosUniq.push({ id: e.ciudad, name: e.ciudad });
    });
    return destinosUniq;
  };

  const destinosIntereses = () => {
    let destinosUniq = [];
    experiencias.map((experiencia) => {
      experiencia.tags.split(",").map((e, index) => {
        destinosUniq.filter((f) => f.name === e).length === 0 &&
          e !== "" &&
          destinosUniq.push({ id: e, name: e.charAt(0).toUpperCase() + e.slice(1) });
      });
    });
    return destinosUniq;
  };

  const FiltersDialog = () => {
    const title = <div className="filtersDialog-header">Filtros</div>;
    const precio = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Precio</div>
        <div className="filtersDialog-body">
          <div style={{ padding: "0 5px 15px 5px", width: "50%" }}>
            <Input label="" id="filters_precio_min" suggestions={[]} explanation={"Precio mín."} handleInput={null} value={0} />
          </div>
          <div style={{ padding: "0 5px 15px 5px", width: "50%" }}>
            <Input label="" id="filters_precio_max" suggestions={[]} explanation={"Precio máx."} handleInput={null} value={0} />
          </div>
        </div>
      </div>
    );

    const idioma = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Idioma</div>
        <div className="filtersDialog-body">
          <OptionsGroup
            label={""}
            name="filters_idioma"
            options={[
              { id: "ES", name: "Español" },
              { id: "EN", name: "Inglés" },
              { id: "FR", name: "Francés" },
              { id: "IT", name: "Italiano" },
            ]}
          />
        </div>
      </div>
    );
    const destinos = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Destinos</div>
        <div className="filtersDialog-body">
          <OptionsGroup label={""} name="filters_destinos" options={destinosExperiencias()} />
        </div>
      </div>
    );
    const intereses = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Intereses</div>
        <div className="filtersDialog-body">
          <OptionsGroup label={""} name="filters_intereses" options={destinosIntereses()} />
        </div>
      </div>
    );
    const duracion = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Duración</div>
        <div className="filtersDialog-body">
          <OptionsGroup
            label={""}
            name="filters_duracion"
            options={[
              { id: "0-180", name: "0-3 horas" },
              { id: "180-300", name: "3-5 horas" },
              { id: "300-1440", name: "Día completo" },
              { id: "1440-999999999999999", name: "Varios días" },
            ]}
          />
        </div>
      </div>
    );

    const horario = (
      <div className="filtersDialog-group">
        <div className="filtersDialog-title">Horario</div>
        <div className="filtersDialog-body">
          <OptionsGroup
            label={""}
            name="filters_horario"
            options={[
              { id: 1, name: "Mañana, 08:00-12:00" },
              { id: 2, name: "Mediodia-Tarde, 12:00-17:00" },
              { id: 3, name: "Tarde-Noche, 17:00-00:00" },
            ]}
          />
        </div>
      </div>
    );

    const saveChanges = (
      <div className="filtersDialog-footer">
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={handleFilters}>
          <FiSave />
        </button>
      </div>
    );

    return (
      <ModalComponent
        openModal={activeFilters}
        handleClose={() => null}
        content={
          <>
            {horario}
            {destinos}
            {intereses}
            {precio}
            {idioma}
            {duracion}
          </>
        }
        customStyles={{ width: 600, height: 400 }}
        header={title}
        footer={saveChanges}
      />
    );
  };

  const Filters = () => {
    return (
      <div style={{ display: "inline-flex", width: "100%", padding: "1%" }}>
        <div style={{ display: "inline-flex", width: "50%", justifyContent: "flex-start" }}>
          <div className="actionButton">
            <div onClick={handleActiveFilters} style={{ display: "inline-flex", width: "100%" }}>
              <div>Filtros</div>
              <FiSliders style={{ padding: "4px", transform: "rotate(90deg)" }} />
            </div>
            {activeFilters && <FiltersDialog />}
          </div>
        </div>

        <div style={{ display: "inline-flex", width: "50%", justifyContent: "flex-end", paddingRight: "20px" }}>
          <div style={{ marginTop: "22px", marginRight: "10px" }}>Ordenar por: </div>
          <div>
            <Select
              label={""}
              data={[
                { id: "Relevancia", value: "Relevancia" },
                { id: "PrecioMinMax", value: "Precio - de menor a mayor" },
                { id: "PrecioMaxMin", value: "Precio - de mayor a menor" },
                { id: "Valoracion", value: "Valoración" },
              ]}
              id={"search_order"}
              onChange={orderExperiences}
            />
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    experiencias.length > 0 && updateExperiencesFiltered(filters, orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiencias]);

  return (
    <div className="rowContent">
      <div className="contentStretch">
        <div className="featuredListSubtitle">Experiencias culturales inolvidables</div>
        <Filters />
        <div style={{ width: "100%", padding: "12px" }}>{experiencesFiltered.length} actividades encontradas</div>
        {experiencesFiltered && experiencesFiltered.length > 0 && <PaginationList data={experiencesFiltered} resultPerPage={4} />}
      </div>
    </div>
  );
};

export default ActivitiesFilters;
