export const ModalMessage = ({ msg, callback }) => {
  return (
    <div className="modalBackground">
      <div className="modalMessage">
        {msg}
        <div>
          <img src="https://cdnl.iconscout.com/lottie/premium/thumb/loading-dots-5711313-4752313.gif" />
        </div>
        <div>
          <button className="link" style={{ marginTop: "10px", color: "darkred" }} onClick={() => callback("")}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};
