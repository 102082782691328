import React from "react";
//Redux
//Components
import { FiEdit2 } from "react-icons/fi";
//API
//Styles
import "./style.css";

export const CardSmall = ({ e, callback }) => {
  return (
    <div className="cardSmall">
      <div className="cardSmallImage" style={{ backgroundImage: "url(" + e.photos.split(",")[0] + ")" }}></div>
      <div className="cardSmallBody">
        <p className="cardSmallTitle">{e.experienceName}</p>
        <div className="cardSmallSubTitle">{e.shortdesc}</div>
        <div className="row cardSmallActions">
          <div className="col">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
            </div>
          </div>
          <div className="col" style={{ textAlign: "end" }} onClick={() => callback()}>
            <FiEdit2 size={20} color={"#3a66fb"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSmall;
