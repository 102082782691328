import { useState } from "react";
//API
import { postClient } from "./../../api/requests";
//Styles
import "./style.css";

export const Newsletter = () => {
  const [emailSuscribed, setEmailSuscribed] = useState(false);
  const [infoResponse, setInfoResponse] = useState("");
  const handleNewsletterEmail = async () => {
    const email = document.getElementById("newsletterEmail").value;
    const data = {
      email: email,
    };
    const response = await postClient(data);
    if (response.email) {
      setEmailSuscribed(true);
      setInfoResponse("");
      //TODO: Send confirmation to email and if client confirm, change BD field confirmation 0 to 1
    } else {
      console.log("Failed email suscription");
      setInfoResponse("No es posible registrarse con ese email.");
    }
  };

  const NewsletterActions = () => {
    return (
      <div className="newsletterInfo">
        <div>
          <h2>El mundo te está esperando</h2>
        </div>
        <div>Suscríbete a nuestra newsletter y descubre experiencias con las que te enamorarás de tus viajes.</div>
        <div className="newsletterActions">
          <div className="w-75">
            <input id="newsletterEmail" />
          </div>
          <div className="w-25">
            <button onClick={() => handleNewsletterEmail()}>Registrarse</button>
          </div>
          <div className="newsletterInfoResponse">{infoResponse}</div>
        </div>
      </div>
    );
  };

  const SubscribeCorrectly = () => {
    return (
      <div className="newsletterInfo">
        <div className="w-100 pt-5">
          <h3>¡Gracias por suscribirte!</h3>
          <p>Revisa tu correo para confirmar tu suscripción.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="rowContent">
      <div className="contentStretch">
        <div className="newsletterBlock">
          <div className="w-50">
            <div
              className="newsletterImg"
              style={{
                backgroundImage:
                  "url(http://res.cloudinary.com/db5mtfn0l/image/upload/v1698736060/kayak-los-gigantes-avistamiento-cetaceos_orsaww.jpg)",
              }}
            ></div>
          </div>
          <div className="w-50" style={{ backgroundColor: "#96d3ff" }}>
            {!emailSuscribed ? <NewsletterActions /> : <SubscribeCorrectly />}
          </div>
          <div className="w-100 newsletterPrivacyText">
            Al registrarte, aceptas recibir correos electrónicos promocionales sobre actividades y recomendaciones. Puedes cancelar tu suscripción o
            retirar tu consentimiento con efecto futuro en cualquier momento. Para más información:{" "}
            <a href="/politica-privacidad">Política de privacidad</a>.
          </div>
        </div>
      </div>
    </div>
  );
};
