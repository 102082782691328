import React, { useState } from "react";
//Reducers
import { setUserData, setAlert } from "../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import { Stack } from "react-bootstrap";
import { FiSave, FiDownloadCloud } from "react-icons/fi";
import { Input, Select, OptionsGroup, InputWOptions } from "../../../../components/forms";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//API
import { updateProveedor } from "../../../../api/requests";

export const Bussiness = ({ handleModalMessage, getProveedorConfig, getTicandoData }) => {
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);
  const dispatch = useDispatch();

  const sincronizeExperience = () => {
    getTicandoData();
    handleModalMessage("Sincronizando experiencias, espere por favor...");
  };

  const updateData = async () => {
    let data = {
      estadolegal: document.getElementById("register_estadolegal").value,
      dnicif: document.getElementById("register_dnicif").value,
      direccionfiscal: document.getElementById("register_direccionfiscal").value,
      ciudad: document.getElementById("register_ciudad").value,
      cp: document.getElementById("register_cp").value,
      region: document.getElementById("register_region").value,
      pais: document.getElementById("register_pais").value,
      moneda: document.getElementById("register_moneda").value,
      actividades: Array.prototype.slice
        .call(document.querySelectorAll("input[name=register_actividades]:checked"))
        .map((el) => el.value)
        .toString(),
      destinos: Array.prototype.slice
        .call(document.getElementsByClassName("class_register_destino"))
        .map((d) => d.innerHTML)
        .toString(),
      web: document.getElementById("register_web").value,
      publicApiToken: document.getElementById("publicApiToken").value,
      activeSection: "bussiness",
      id: userData.id,
    };

    const response = await updateProveedor(data);
    if (response) {
      //dispatch(setUserData(response[0]));
      setTimeout(() => {
        if (data.publicApiToken !== "") {
          getTicandoData();
          handleModalMessage("Sincronizando experiencias, espere por favor...");
        } else getProveedorConfig();
      }, 1500);

      dispatch(setAlert(1));
    }
  };

  return (
    <>
      <div className="section">
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => updateData()}>
          <FiSave />
        </button>
        <Tabs defaultActiveKey="empresa" id="tab-component" className="mb-3 mt-5">
          <Tab eventKey="empresa" title="Empresa">
            <div className="row">
              <div className="col-3 mb-3">
                <Select
                  label="Estado legal"
                  id="register_estadolegal"
                  data={[
                    { id: 1, value: "Empresa" },
                    { id: 2, value: "Autónomo" },
                  ]}
                />
              </div>
              <div className="col-3 mb-3">
                <Input label="CIF/DNI" id="register_dnicif" suggestions={[]} explanation={""} handleInput={null} />
              </div>
              <div className="col-6 mb-3">
                <Input
                  label="Dirección fiscal"
                  id="register_direccionfiscal"
                  suggestions={[]}
                  explanation={""}
                  handleInput={null}
                  value={userData.direccionfiscal}
                />
              </div>

              <div className="col-6 mb-3">
                <Input label="Ciudad" id="register_ciudad" suggestions={[]} explanation={""} handleInput={null} value={userData.ciudad} />
              </div>
              <div className="col-2 mb-3">
                <Input label="Código postal" id="register_cp" suggestions={[]} explanation={""} handleInput={null} value={userData.cp} />
              </div>

              <div className="col-2 mb-3">
                <Input label="Región" id="register_region" suggestions={[]} explanation={""} handleInput={null} value={userData.region} />
              </div>
              <div className="col-2 mb-3">
                <Select
                  label="Pais"
                  id="register_pais"
                  data={[
                    { id: 1, value: "España" },
                    { id: 2, value: "Francia" },
                  ]}
                  selected={userData.pais}
                />
              </div>
              <div className="col-3 mb-3">
                <Select
                  label="Moneda"
                  id="register_moneda"
                  data={[
                    { id: 1, value: "Euro" },
                    { id: 2, value: "Dolar" },
                  ]}
                  selected={userData.moneda}
                />
              </div>
            </div>
          </Tab>

          <Tab eventKey="servicios" title="Servicios">
            <div className="row">
              <div className="col-6">
                <h5 style={{ marginTop: "5px" }}>Tipo de actividades</h5>
                <OptionsGroup
                  label={""}
                  name="register_actividades"
                  options={[
                    { id: 1, name: "Actividades deportivas" },
                    { id: 2, name: "Alquiler de vehículos" },
                    { id: 3, name: "Cruceros" },
                    { id: 4, name: "Hop-on Hop-off" },
                    { id: 5, name: "Guias turísticos" },
                    { id: 6, name: "Spas" },
                    { id: 7, name: "Experiencias gastronómicas" },
                  ]}
                  selecteds={userData.actividades}
                />
              </div>
              <div className="col-6">
                <h5 style={{ marginTop: "5px" }}>Destinos</h5>
                <InputWOptions
                  label=""
                  id="register_destino"
                  suggestions={[{ name: "Córdoba" }, { name: "Sevilla" }]}
                  explanation={"Destinos donde operas"}
                  selecteds={userData.destinos}
                />
              </div>
              <div className="col-12">
                <h5 style={{ marginTop: "50px" }}>Web</h5>
                <Input label="" id="register_web" suggestions={[]} explanation={""} handleInput={null} value={userData.web} />
              </div>
            </div>
          </Tab>

          <Tab eventKey="token" title="Token">
            <Stack direction="horizontal" className="p-3">
              <div style={{ width: "95%" }}>
                <Input
                  label="API Token público"
                  id="publicApiToken"
                  suggestions={[]}
                  explanation={""}
                  handleInput={null}
                  value={userData.publicApiToken}
                />
              </div>
              <button
                className="link"
                style={{ color: "#0140de", fontSize: "25px", float: "right", marginTop: "15px" }}
                onClick={sincronizeExperience}
              >
                <FiDownloadCloud size={35} />
              </button>
            </Stack>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
