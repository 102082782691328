import React, { useEffect } from "react";
//Redux
import { setProductActive, setPageActive } from "../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import { HiOutlineHeart } from "react-icons/hi";
import { DisponibilitySmall } from "../../components/disponibilitySmall";
import { ExperienceDetails } from "./experienceDetails";
//API
import { getExperienceByPath } from "../../api/requests";
import { SearchComponent } from "../../components/searchComponent";
/*import { APIProvider, Map } from "@vis.gl/react-google-maps";*/
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./product.css";

export default function Product() {
  const dispatch = useDispatch();

  const experience = useSelector((state) => state.aplicationConfig.productActive);

  const blockStars = (value) => {
    const style = { "--rating-value": (value / 10) * 5, fontSize: "20px" };
    return <div className="rating" style={style}></div>;
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getUrlData = async () => {
    const pathname = window.location.pathname;
    await getExperienceByPath({ pathName: pathname.replace("/experience/", "") }).then((res) => {
      console.log(pathname);
      console.log(res);
      dispatch(setProductActive(res[0]));
    });
  };

  useEffect(() => {
    getUrlData();
    dispatch(setPageActive("experience"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customSearchStyles = {
    block: {
      marginLeft: "2%",
    },
    input: {
      fontSize: "1rem",
      height: "40px",
    },
    svg: {
      fontSize: "20px",
      margin: "10px",
    },
  };

  const SearchBlock = () => {
    return (
      <div
        style={{
          border: "1px lightgrey solid",
          borderRadius: "50px",
          width: "400px",
          /*position: "absolute",
          marginTop: "-55px",
          marginLeft: "25%",*/
        }}
      >
        <SearchComponent experiencias={[]} showButton={false} customSearchStyles={customSearchStyles} />
      </div>
    );
  };

  const BreadCum = () => {
    return (
      <div className="rowContent" style={{ marginBottom: "-60px" }}>
        <div className="contentStretch">
          <div>
            {experience.tags.split(",").map((t) => {
              return (
                <label style={{ fontSize: "11px", backgroundColor: "#f4f4f4", padding: "3px 7px", marginRight: "3px", color: "#0140de" }}>{t}</label>
              );
            })}
          </div>

          <div style={{ fontSize: "11px", marginTop: "15px" }}>{`${experience.ciudad} > ${experience.localidad} > Visitas guiadas`}</div>

          <div style={{ fontSize: "40px", marginTop: "10px" }}>{experience.experienceName}</div>
        </div>
      </div>
    );
  };

  const InfoBlock = () => {
    return (
      <div className="rowContent infoBlockProduct">
        <div className="contentStretch">
          <div className="d-flex">
            <div className="blockStarts  flex-grow-1">
              <span className="">{blockStars(experience.valoracion)}</span>
              <small className="">
                {" "}
                <b>{" " + experience.valoracion + "/10 "}</b>{" "}
              </small>
              <small className="p-1 link"> +1000 opiniones </small>
              <small className="p-1">
                Proveedor de la actividad: <b className="link">Gray Line Sightseeing</b>
              </small>
            </div>
            <small className="link">
              <HiOutlineHeart className="svgEnd" /> Añadir a favoritos
            </small>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {experience.id !== undefined && (
        <>
          {/*<SearchBlock />*/}
          <BreadCum />
          <InfoBlock />
          <Carousel responsive={responsive}>
            {experience.photos.split(",").map((p) => {
              return <div className="carouselItem" style={{ backgroundImage: `url("${p}")` }}></div>;
            })}
          </Carousel>
          <div className="rowContent infoBlockProduct">
            <div className="contentStretch">
              <div className="experienceBody">
                <div className="experienceDetails">
                  <ExperienceDetails />
                </div>
                {/*<div className="experienceSidebar">
                  <DisponibilitySmall />
                </div>*/}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
