import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Reducers
import { setWishlist } from "./reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";

//Sections
import Header from "./sections/header";
import Footer from "./sections/footer";
import Home from "./pages/home";
//Sections - Admin
import Administracion from "./pages/administracion";
import Proveedores from "./pages/admin/proveedores";
import Clientes from "./pages/admin/clientes";
//Sections - Others
import Account from "./pages/account";
import Product from "./pages/product";
import Search from "./pages/search";
import { Wishlists } from "./pages/wishlists";
import Login from "./pages/login";
import { Information } from "./pages/information";
import { ProtectedRoute } from "./components/security/protectedRoute";

//API
import { getWishlists } from "./api/requests";

function App() {
  const userConfig = useSelector((state) => state.aplicationConfig.userConfig);
  const dispatch = useDispatch();

  const getFavorites = async (idClient) => {
    await getWishlists({ id: idClient }).then((res) => {
      res && res.length > 0 && dispatch(setWishlist(res));
    });
  };

  useEffect(() => {
    if (userConfig.id !== null) getFavorites(userConfig.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {console.log("Rendering APP..")}

      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/proveedores"
            element={
              <ProtectedRoute userConfig={userConfig} page="proveedores">
                <Proveedores />
              </ProtectedRoute>
            }
          />
          <Route
            path="/administracion"
            element={
              <ProtectedRoute userConfig={userConfig} page="administracion">
                <Administracion />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes"
            element={
              <ProtectedRoute userConfig={userConfig} page="clientes">
                <Clientes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wishlists"
            element={
              <ProtectedRoute userConfig={userConfig} page="wishlist">
                <Wishlists />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/experiencia/:pathName" element={<Product />} />
          <Route path="/experience/:pathName" element={<Product />} />
          <Route path="/search/:pathName" element={<Search />} />
          <Route path="/cookies" element={<Information type={"Cookies"} />} />
          <Route path="/aviso-legal" element={<Information type={"Aviso"} />} />
          <Route path="/politica-privacidad" element={<Information type={"Politica"} />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
