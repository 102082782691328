import React, { useState } from "react";
//Reducers
import { setUserData, setAlert } from "../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import { FiSave } from "react-icons/fi";
import { Input } from "../../../../components/forms";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//API
import { updateProveedor } from "../../../../api/requests";

export const Perfil = ({ getProveedorConfig }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);

  const updateData = async () => {
    const data = {
      email: document.getElementById("register_email").value,
      clave: base64_encode(document.getElementById("register_password").value),
      nombre: document.getElementById("register_nombre").value,
      apellidos: document.getElementById("register_apellidos").value,
      razonsocial: document.getElementById("register_razonsocial").value,
      telefono: document.getElementById("register_telefono").value,
      activeSection: "perfil",
      id: userData.id,
    };

    const response = await updateProveedor(data);
    console.log(response);
    if (response) {
      //dispatch(setUserData(response[0]));
      setTimeout(() => {
        getProveedorConfig();
      }, 1500);

      dispatch(setAlert(1));
    }
  };
  return (
    <>
      <div className="section">
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => updateData()}>
          <FiSave />
        </button>
        <Tabs defaultActiveKey="perfil" id="tab-component" className="mb-3 mt-5">
          <Tab eventKey="perfil" title="Perfil">
            <div className="row">
              <div className="col-4 mb-2">
                <Input label="Nombre" id="register_nombre" suggestions={[]} explanation={""} handleInput={null} value={userData.nombre} />
              </div>
              <div className="col-4 mb-2">
                <Input label="Apellidos" id="register_apellidos" suggestions={[]} explanation={""} handleInput={null} value={userData.apellidos} />
              </div>

              <div className="col-4 mb-2">
                <Input
                  label="Razón social"
                  id="register_razonsocial"
                  suggestions={[]}
                  explanation={""}
                  handleInput={null}
                  value={userData.razonsocial}
                />
              </div>
              <div className="col-3 mb-2">
                <Input
                  label="Email"
                  id="register_email"
                  suggestions={[]}
                  explanation={"Lo usarás para identificarte en tu cuenta"}
                  handleInput={null}
                  value={userData.email}
                />
              </div>

              <div className="col-2 mb-2">
                <Input label="Teléfono" id="register_telefono" suggestions={[]} explanation={""} handleInput={null} value={userData.telefono} />
              </div>
            </div>
          </Tab>
          <Tab eventKey="contraseña" title="Contraseña">
            <div className="row">
              <div className="col-6">
                <Input
                  label="Clave"
                  id="register_password"
                  suggestions={[]}
                  explanation={"Mínimo 8 carácteres incluyendo números, mayúsculas y minúsculas"}
                  handleInput={null}
                  value={base64_decode(userData.clave)}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
