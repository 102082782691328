import React, { useState, useRef } from "react";
import { FiChevronRight, FiX, FiPlus, FiMinus } from "react-icons/fi";
import "./styles.css";

export const Input = ({ label, suggestions, id, explanation, handleInput, value = null }) => {
  const [search, setSearch] = useState("");
  const [val, setVal] = useState(value !== null ? value : "");
  const dataRef = useRef(null);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setVal(e.target.value);
  };

  const updateSearch = (value) => {
    dataRef.current.value = value;
    setSearch("");
    handleInput(id);
    setVal(value);
  };

  const SearchSuggestions = () => {
    const filters = suggestions.filter((f) => f.name !== null && f.name.toLowerCase().includes(search.toLowerCase()));
    if (filters.length > 0 && search !== "")
      return (
        <div className="suggestionForm">
          {filters.map((e, index) => {
            return (
              <div key={e.name + "_" + index} className="suggestionFormResult" onClick={() => updateSearch(e.name)}>
                {e.name}
              </div>
            );
          })}
        </div>
      );
  };

  /*const SendButton = () => {
    return (
      <div className="buttonLeft">
        <FiChevronRight style={{ fontSize: "20px" }} onClick={() => handleInput(id)} />
      </div>
    );
  };*/

  return (
    <>
      <p className="labelForm">{label}</p>
      <input
        ref={dataRef}
        id={id}
        className="inputForm"
        type={id.includes("password") ? "password" : "text"}
        onChange={handleSearch}
        value={val}
        onKeyDown={(e) => e.key === "Enter" && handleInput !== null && handleInput(id)}
      />
      <SearchSuggestions />
      {/*handleInput !== null && <SendButton />*/}
      <div style={{ fontSize: "11px", color: "gray" }}>{explanation}</div>
    </>
  );
};

export const Select = ({ label, data, id, selected = null, onChange = () => null }) => {
  return (
    <>
      <p className="labelForm">{label}</p>
      <select className="inputForm" id={id} key={label} onChange={onChange}>
        {data.map((opt, index) => {
          if (selected === opt.id.toString()) {
            return (
              <option className="selectOption" key={label + "_" + index} value={opt.id} selected>
                {opt.value}
              </option>
            );
          } else {
            return (
              <option className="selectOption" key={label + "_" + index} value={opt.id}>
                {opt.value}
              </option>
            );
          }
        })}
      </select>
    </>
  );
};

export const OptionsGroup = ({ label, options, name, selecteds = "" }) => {
  return (
    <>
      <p className="labelForm" key={label}>
        {label}
      </p>
      {options.map((opt, index) => {
        return (
          <div className="optionsGroup" style={{ display: "inline-flex", width: "50%" }} key={label + "_" + index}>
            {selecteds !== "" && selecteds.split(",").includes(opt.id.toString()) && (
              <input type="checkbox" className="optionGroup" name={name} value={opt.id} style={{ width: "21px", height: "20px" }} checked />
            )}
            {selecteds === "" || !selecteds.split(",").includes(opt.id.toString()) ? (
              <input type="checkbox" className="optionGroup" name={name} value={opt.id} style={{ width: "21px", height: "20px" }} />
            ) : (
              ""
            )}
            <label style={{ fontSize: "15px", padding: "3px" }}>{opt.name}</label>
          </div>
        );
      })}
    </>
  );
};

export const InputWOptions = ({ label, id, suggestions, explanation, selecteds = "" }) => {
  const [destinosElegidos, setDestinosElegidos] = useState(selecteds !== "" ? selecteds.split(",") : []);
  const handleDestinos = (id) => {
    console.log("handleDestinos: " + id);
    let destino = document.getElementById(id).value;
    setDestinosElegidos([...destinosElegidos, destino]);
  };

  const removeDestinos = (value) => {
    let destinosActualizados = destinosElegidos.filter((d) => d !== value);
    setDestinosElegidos(destinosActualizados);
  };
  return (
    <>
      <Input label={label} id={id} suggestions={suggestions} explanation={explanation} handleInput={handleDestinos} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        {destinosElegidos.map((d, index) => {
          return (
            <div key={d + "_" + index} className="destinoItemRegisterForm" onClick={() => removeDestinos(d)}>
              <label className={"class_" + id}>{d}</label>
              <sup>
                <FiX />
              </sup>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const InputWOptionsV2 = ({ label, id, suggestions, explanation, selecteds = "", textColor }) => {
  const [options, setOptions] = useState(selecteds !== "" ? selecteds.split(",") : []);
  const handleDestinos = () => {
    console.log("handleOptions: " + id);
    let opt = document.getElementById(id).value;
    setOptions([...options, opt]);
    document.getElementById(id).value = "";
  };

  const removeDestinos = (value) => {
    let destinosActualizados = options.filter((d) => d !== value);
    setOptions(destinosActualizados);
  };
  return (
    <>
      <div style={{ display: "inline-flex", width: "100%" }}>
        <FiPlus style={{ paddingTop: "10px", fontSize: "20px", color: "#000289" }} />
        <div style={{ paddingLeft: "15px", width: "85%" }}>
          <input
            placeholder={label}
            id={id}
            type="text"
            style={{ width: "100%", border: "0", borderBottom: "1px lightgray solid", borderRadius: "0" }}
            onKeyDown={(e) => (e.key === "Enter" ? handleDestinos() : "")}
          />
        </div>
      </div>

      <div style={{ width: "100%", marginTop: "15px" }}>
        {options.map((d, index) => {
          return (
            <div key={d + "_" + index} style={{ display: "inline-flex", width: "100%", marginTop: "5px" }} onClick={() => removeDestinos(d)}>
              <FiMinus style={{ paddingLeft: "35px", fontSize: "20px", color: "darkred" }} />
              <div style={{ paddingLeft: "15px", width: "80%", color: textColor }}>
                <label className={"class_" + id}>{d}</label>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
