//Reducers
import { setAdmExperienceSelected } from "../../../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import { FiUpload, FiPlus } from "react-icons/fi";
import { CgRemoveR } from "react-icons/cg";

export const Galery = () => {
  const dispatch = useDispatch();
  const experienceSelected = useSelector((state) => state.aplicationConfig.adm.experiences.selected);

  const handlePhotos = (type, data) => {
    let photosObj = { principal: experienceSelected.photos.split(",")[0], secondary: experienceSelected.photos.split(",").slice(1) };
    if (type === "principal") photosObj.principal = data.url;
    if (type === "secondary") photosObj.secondary = [...photosObj.secondary, data.url];
    console.log(photosObj);
    dispatch(setAdmExperienceSelected({ ...experienceSelected, photos: photosObj.principal.toString() + "," + photosObj.secondary.toString() }));
  };

  const removePhoto = (id) => {
    let photos = experienceSelected.photos.split(",");
    photos = photos.filter((f, index) => index !== parseInt(id));
    dispatch(setAdmExperienceSelected({ ...experienceSelected, photos: photos.toString() }));
  };

  const uploadImage = (photo, type) => {
    let image = photo.target.files[0];
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ml_default");
    data.append("cloud_name", "db5mtfn0l");
    fetch("https://api.cloudinary.com/v1_1/db5mtfn0l/image/upload", { method: "post", body: data })
      .then((resp) => resp.json())
      .then((data) => {
        handlePhotos(type, data);
        document.getElementById("inputFileSecondary").value = "";
      });
  };

  return (
    <div>
      <div style={{ width: "100%", padding: "0 15px" }}>
        {experienceSelected.photos.split(",").length > 0 && (
          <>
            <div
              style={{
                backgroundImage: `url(${experienceSelected.photos.split(",")[0]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "300px",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            ></div>
            <div style={{ textAlign: "right" }}>
              <label
                style={{
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
              >
                <FiUpload style={{ fontSize: "18px", color: "#000289", backgroundColor: "white" }} />
                <input type="file" style={{ display: "none" }} onChange={(e) => uploadImage(e, "principal")} />
              </label>
            </div>
          </>
        )}
        {experienceSelected.photos.split(",").length === 0 && (
          <label
            style={{
              width: "100%",
              height: "300px",
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor: "#ececec",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FiPlus style={{ fontSize: "125px", color: "#000289" }} />
            <input type="file" style={{ display: "none" }} onChange={(e) => uploadImage(e, "secondary")} />
          </label>
        )}
      </div>
      <div style={{ width: "100%", padding: "0 15px" }}>
        <div style={{ marginBottom: "15px", marginTop: "30px" }}>
          Alternativas <small>({experienceSelected.photos.split(",").slice(1).length - 1}/12)</small>
        </div>
        <div style={{ width: "100%", display: "inline-flex", flexWrap: "wrap", gap: "10px" }}>
          {experienceSelected.photos.split(",").slice(1).length > 0 &&
            experienceSelected.photos
              .split(",")
              .slice(1)
              .map((photo, index) => {
                return (
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${photo})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "100px",
                        height: "100px",
                        borderRadius: "5px",
                        marginRight: "10px",
                      }}
                    ></div>
                    <div style={{ textAlign: "right", padding: "0 10px" }}>
                      <CgRemoveR className="link" color={"darkred"} onClick={() => removePhoto(index + 1)} />
                    </div>
                  </div>
                );
              })}
          {experienceSelected.photos.split(",").slice(1).length < 12 && (
            <label
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#ececec",
              }}
            >
              <FiPlus style={{ fontSize: "100px", padding: "35%", color: "#000289" }} />
              <input id="inputFileSecondary" type="file" style={{ display: "none" }} onChange={(e) => uploadImage(e, "secondary")} />
            </label>
          )}
        </div>
      </div>
    </div>
  );
};
