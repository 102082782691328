import React, { useState } from "react";
//Reducers
import { useSelector } from "react-redux";
//Components
import { Input } from "../../../../../../components/forms";

export const Address = ({ countries, countriesStates }) => {
  const experienceSelected = useSelector((state) => state.aplicationConfig.adm.experiences.selected);
  const [countrySelected, setCountrySelected] = useState("España");
  const handleState = (e) => {
    console.log(e);
    console.log(document.getElementById(e).value);
    setCountrySelected(document.getElementById(e).value);
    console.log(
      countriesStates
        .filter((c_s) => c_s.native === document.getElementById(e).value)[0]
        .states.map((s) => {
          return { id: s.id, value: s.name };
        })
    );
  };
  return (
    <>
      <h5 style={{ marginTop: "5px" }}>Ubicación</h5>

      <div className="row">
        <div className="col-12 mt-4">
          <Input
            label="Pais"
            id="experience_pais"
            suggestions={
              countries !== undefined
                ? countries.map((c) => {
                    return { id: c.id, name: c.native };
                  })
                : []
            }
            explanation={""}
            handleInput={handleState}
            value={experienceSelected.pais || ""}
          />
        </div>
        <div className="col-12 mt-4">
          <Input
            label="Ciudad"
            id="experience_ciudad"
            suggestions={
              countriesStates
                ? countriesStates
                    .filter((c_s) => c_s.native === countrySelected)[0]
                    .states.map((s) => {
                      return { id: s.id, name: s.name };
                    })
                : []
            }
            explanation={""}
            handleInput={() => null}
            value={experienceSelected.ciudad || ""}
          />
        </div>
        <div className="col-12 mt-4">
          <Input
            label="Localidad"
            id="experience_localidad"
            suggestions={[]}
            explanation={""}
            handleInput={null}
            value={experienceSelected.localidad || ""}
          />
        </div>
      </div>
      <div className="col-12 mt-4">
        <Input
          label="Dirección"
          id="experience_direccion"
          suggestions={[]}
          explanation={""}
          handleInput={null}
          value={experienceSelected.direccion || ""}
        />
      </div>
    </>
  );
};
