import React, { useState, useEffect } from "react";
//Reducers
import { setAdmExperienceSelected } from "../../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import { CardSmall } from "../../../../../components/cardSmall";
//Api
import { getProveedorConfiguration } from "../../../../../api/requests";

export const List = () => {
  const dispatch = useDispatch();
  const userDataId = useSelector((state) => state.aplicationConfig.userConfig.userData.id);
  const experienceSelected = useSelector((state) => state.aplicationConfig.adm.experiences.selected);
  const [experiencias, setExperiencias] = useState([]);

  const getExperiences = async () => {
    const data = {
      id: userDataId,
    };
    await getProveedorConfiguration(data).then((res) => {
      setExperiencias(res.proveedor.experiencias);
    });
  };

  const handleExperience = (experience) => {
    dispatch(setAdmExperienceSelected(experience));
  };

  useEffect(() => {
    getExperiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "inline-flex", width: "100%", flexWrap: "wrap" }}>
      {experienceSelected === null &&
        experiencias &&
        experiencias.map((e, index) => {
          return <CardSmall key={"exp" + index} e={e} callback={() => handleExperience(e)} />;
        })}
    </div>
  );
};

/*
        {/*<div className={"experienceAdminRow"} onClick={() => handleExperience(e)}>
          <div style={{ width: "10%" }}>
            <img
              src={
                e.photos !== ""
                  ? e.photos.split(",")[0]
                  : "https://al-akshadevelopers.com/wp-content/plugins/gallery-videos/public/img/tsvg_no_img.jpg"
              }
              alt={e.experienceName}
              width="100%"
            />
          </div>
          <div style={{ padding: "5px", width: "85%" }}>
            <label>{e.experienceName}</label>
            <p style={{ marginTop: "5px", fontSize: "12px" }}>{e.experienceDescription}</p>
          </div>
          <div style={{ paddingTop: "3%", width: "5%" }}>
            <FiChevronRight />
          </div>
            </div>
*/
