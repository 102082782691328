import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

export default function PieComponent({ full = true, data, COLORS }) {
  return (
    <ResponsiveContainer>
      <PieChart>
        <Legend />
        {full && (
          <Pie data={data} cx={120} cy={100} innerRadius={60} outerRadius={80} fill="#8884d8" paddingAngle={5} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        )}
        {!full && (
          <Pie
            data={data}
            cx={120}
            cy={100}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            cornerRadius={5}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
