import React from "react";
//Reducers
//Components
import { Selected } from "./selected";
import { List } from "./list";
//API

export const Experiences = ({ countries, countriesStates, webConfiguration }) => {
  return (
    <>
      <div className="section">
        <List />
        <Selected countries={countries} countriesStates={countriesStates} webConfiguration={webConfiguration} />
      </div>
    </>
  );
};
