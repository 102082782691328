import React, { useState, useEffect } from "react";
//Components
import Searcher from "../sections/searcherHuge";
import ActivitiesFilters from "../sections/activitiesFilters";
//API
import { getExperiencesBySearch } from "../api/requests";

export default function Search() {
  const pathname = window.location.pathname;
  const [experiencias, setExperiencias] = useState([]);

  const getUrlData = async () => {
    await getExperiencesBySearch({ pathName: pathname.replace("/search/", "") }).then((res) => {
      setExperiencias(res);
    });
  };

  useEffect(() => {
    getUrlData();
  }, []);

  return (
    <>
      <Searcher experiencias={experiencias} value={pathname.replace("/search/", "")} />
      <ActivitiesFilters experiencias={experiencias} />
    </>
  );
}
