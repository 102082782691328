import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FiSearch, FiCalendar, FiChevronDown } from "react-icons/fi";
import "./style.css";
import { FontWeight } from "@cloudinary/url-gen/qualifiers";
import { useNavigate } from "react-router-dom";

export const Searcher = ({ experiencias, value }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleSearching = (e) => {
    if (e.key === "Enter") {
      console.log("do validate: " + e.target.value);
      navigate("/search/" + e.target.value);
    }
  };

  const handleProduct = (e) => {
    navigate("/experience/" + e.pathName);
  };

  const SearchSuggestions = () => {
    const experiencesFilters = experiencias.filter(
      (f) =>
        f.experienceName.toLowerCase().includes(search.toLowerCase()) ||
        f.experienceDescription.toLowerCase().includes(search.toLowerCase()) ||
        f.ciudad.toLowerCase().includes(search.toLowerCase()) ||
        f.pais.toLowerCase().includes(search.toLowerCase())
    );
    if (experiencesFilters.length > 0 && search !== "")
      return (
        <div className="searchSuggestions">
          {experiencesFilters.map((e) => {
            return (
              <div className="searchSuggestionsResult" onClick={() => handleProduct(e)}>
                <div className="searchSuggestionsImg">
                  <img src={e.photos.split(",")[0]} />
                </div>
                <div className="searchSuggestionsDesc">
                  <div>{e.experienceName}</div>
                  <div style={{ fontWeight: "400", fontSize: "12px" }}>
                    {e.ciudad} ({e.pais})
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
  };

  return (
    <div className="rowContent" style={{ borderTop: "1px #e6e6e6 solid", paddingTop: "25px" }}>
      <div className="contentStretch" style={{ display: "inline-flex" }}>
        <div className="searcherInlineSmall" style={{ border: "1px lightgrey solid", width: "65%" }}>
          <FiSearch /> <input type="text" onChange={handleSearch} onKeyDown={handleSearching} placeholder="Estoy buscando..." value={value} />
          <Button onClick={() => handleSearching({ key: "Enter", target: { value: search } })}>Buscar</Button>
        </div>
        <div className="searcherInlineSmall" style={{ border: "1px lightgrey solid", width: "35%" }}>
          <FiCalendar />
          <div style={{ fontSize: "1.3rem", fontWeight: "600", paddingTop: "20px" }}>Añadir fechas</div>
          <FiChevronDown style={{ right: "15px", position: "absolute" }} />
        </div>
        <SearchSuggestions />
      </div>
    </div>
  );
};

export default Searcher;
