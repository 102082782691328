import React, { useState } from "react";
//Reducers
import { useSelector, useDispatch } from "react-redux";
import { setUserData, setAlert } from "../../../../reducers/userConfigSlice";
//Components
import { Input } from "../../../../components/forms";
import { FiSave } from "react-icons/fi";
import { decode as base64_decode, encode as base64_encode } from "base-64";
//API
import { updateClient } from "../../../../api/requests";

export const Perfil = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.aplicationConfig.userConfig.userData);

  const updateData = async () => {
    let data = {
      email: document.getElementById("register_email").value,
      clave: base64_encode(document.getElementById("register_password").value),
      nombre: document.getElementById("register_nombre").value,
      apellidos: document.getElementById("register_apellidos").value,
      telefono: document.getElementById("register_telefono").value,
      activeSection: "perfil",
      id: userData.id,
    };

    const response = await updateClient(data);
    if (response) {
      //dispatch(setUserData(response[0]));
      dispatch(setAlert(1));
    }
  };

  return (
    <>
      <h2>Perfil</h2>
      <div className="section">
        <button className="link" style={{ color: "#0140de", fontSize: "25px", float: "right" }} onClick={() => updateData()}>
          <FiSave />
        </button>
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div className="sub-section-2">
            <Input label="Nombre" id="register_nombre" suggestions={[]} explanation={""} handleInput={null} value={userData.nombre} />
          </div>
          <div className="sub-section-2">
            <Input label="Apellidos" id="register_apellidos" suggestions={[]} explanation={""} handleInput={null} value={userData.apellidos} />
          </div>
        </div>
        <Input
          label="Email"
          id="register_email"
          suggestions={[]}
          explanation={"Lo usarás para identificarte en tu cuenta"}
          handleInput={null}
          value={userData.email}
        />
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div className="sub-section- d-none">
            <Input
              label="Clave"
              id="register_password"
              suggestions={[]}
              explanation={"Mínimo 8 carácteres incluyendo números, mayúsculas y minúsculas"}
              handleInput={null}
              value={base64_decode(userData.clave)}
            />
          </div>
          <div className="sub-section-2">
            <Input label="Teléfono" id="register_telefono" suggestions={[]} explanation={""} handleInput={null} value={userData.telefono} />
          </div>
        </div>
      </div>
    </>
  );
};
