export const demoBooks = [
  {
    experience: "Senderismo",
    bookings: [
      { date: "2024-05-14T09:30:00", name: "Senderismo en el bosque" },
      { date: "2024-05-14T19:30:00", name: "Senderismo en el bosque" },
      { date: "2024-05-15T11:45:00", name: "Excursión a la montaña" },
      { date: "2024-05-22T11:45:00", name: "Excursión a la montaña" },
    ],
  },
  {
    experience: "Buceo",
    bookings: [{ date: "2024-05-16T14:15:00", name: "Inmersión en el arrecife" }],
  },
  {
    experience: "Surf",
    bookings: [
      { date: "2024-05-16T08:00:00", name: "Lección de surf en la playa" },
      { date: "2024-05-17T10:30:00", name: "Sesión de surf al atardecer" },
      { date: "2024-05-23T10:30:00", name: "Sesión de surf al atardecer" },
    ],
  },
  {
    experience: "Camping",
    bookings: [
      { date: "2024-05-14T17:20:00", name: "Acampada bajo las estrellas" },
      { date: "2024-05-14T20:20:00", name: "Acampada bajo las estrellas" },
    ],
  },
  {
    experience: "Tour gastronómico",
    bookings: [
      { date: "2024-05-17T08:45:00", name: "Degustación de platos locales" },
      { date: "2024-05-17T15:45:00", name: "Degustación de platos locales" },
      { date: "2024-05-17T22:45:00", name: "Degustación de platos locales" },
      { date: "2024-05-26T12:45:00", name: "Degustación de platos locales" },
    ],
  },
  {
    experience: "Turismo urbano",
    bookings: [{ date: "2024-05-20T16:00:00", name: "Tour por la ciudad" }],
  },
];
