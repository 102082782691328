import React, { useState, useEffect } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BloqueConfiguracion from "./../components/bloqueConfiguracion";
import { FiCircle, FiLogIn } from "react-icons/fi";
//API
import { updateUserState, getWebConfiguration } from "./../api/requests";
//Reducers
import { setUserConfig } from "./../reducers/userConfigSlice";
//Datatable
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./administracion.css";

function Administracion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("dashboard");
  const [webConfiguration, setWebConfiguration] = useState({});

  const updateState = async (user) => {
    const data = {
      id: user.id,
      state: user.state === "pendiente" ? "activo" : "pendiente",
    };
    await updateUserState(data).then(async (res) => {
      setWebConfiguration({ ...webConfiguration, proveedores: res });
    });
  };

  const addCategoriaExperiencias = (e) => {
    console.log(e);
  };

  const addTipoActividad = (e) => {};

  const getWebConfig = async () => {
    console.log("getWebConfig")
    await getWebConfiguration().then((res) => {
      console.log(res);
      setWebConfiguration(res);
    });
  };

  const proveedorPanel = async (row) => {
    dispatch(setUserConfig({ logged: true, userData: row }));
    navigate("/proveedores");
  };

  const MenuAdministracion = () => {
    return (
      <div className="menuProveedores">
        <div className={activeSection === "dashboard" ? "activeSection" : ""} onClick={() => setActiveSection("dashboard")}>
          Escritorio
        </div>
        <div className={activeSection === "users" ? "activeSection" : ""} onClick={() => setActiveSection("users")}>
          Usuarios
        </div>
        <div className={activeSection === "configurations" ? "activeSection" : ""} onClick={() => setActiveSection("configurations")}>
          Configuraciones
        </div>
        <div style={{ color: "darkred" }} onClick={() => logout()}>
          Salir
        </div>
      </div>
    );
  };

  const logout = () => {
    dispatch(setUserConfig({ logged: false, userData: {} }));
    navigate("/login");
  };

  const SectionAdministracion = () => {
    switch (activeSection) {
      case "dashboard":
        const data = [
          { name: "22/06", visitas: 20 },
          { name: "23/06", visitas: 40 },
          { name: "24/06", visitas: 110 },
          { name: "25/06", visitas: 130 },
          { name: "26/06", visitas: 160 },
        ];
        return (
          <>
            <h2>Escritorio</h2>
            <div className="section">
              <p style={{ color: "grey" }}>Visitas en la última semana</p>
              <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ height: "300px", width: "80%" }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={450}
                      height={350}
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <XAxis dataKey="name" />

                      <Tooltip />
                      <Area type="monotone" dataKey="visitas" stroke="#000289" fill="#0140de" />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <div style={{ padding: "15px" }}>
                    <div style={{ color: "#0140de", fontWeight: "bold", fontSize: "40px" }}>61</div>
                    <div style={{ color: "grey" }}>Ventas totales</div>
                  </div>
                  <div style={{ borderBottom: "1px lightgrey solid", margin: "15px" }}></div>
                  <div style={{ padding: "15px" }}>
                    <div style={{ color: "#0140de", fontWeight: "bold", fontSize: "40px" }}>30+</div>
                    <div style={{ color: "grey" }}>Visitas diarias</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case "users":
        return (
          <>
            <h2>Usuarios</h2>
            <div className="section" style={{ display: "inline-flex", width: "97%" }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Apellidos</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Panel</TableCell>
                      <TableCell align="center">Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webConfiguration && webConfiguration.proveedores && webConfiguration.proveedores.map((row) => (
                      <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.nombre}</TableCell>
                        <TableCell>{row.apellidos}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <FiLogIn style={{ cursor: "pointer" }} onClick={() => proveedorPanel(row)} />
                        </TableCell>
                        <TableCell align="center" onClick={() => updateState(row)} style={{ cursor: "pointer" }}>
                          {row.state === "pendiente" ? <FiCircle style={{ color: "#ffcb00" }} /> : <FiCircle style={{ color: "darkgreen" }} />}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        );
      case "configurations":
        return (
          <>
            <h2>Configuraciones</h2>
            <div className="" style={{ display: "inline-flex", width: "100%" }}>
              <BloqueConfiguracion
                title={"Categorías de experiencias"}
                data={webConfiguration.experiencias}
                headers={["Nombre", "Portada", "Acción"]}
                width={"30%"}
                showColumns={["name", "portada"]}
                handleInput={addCategoriaExperiencias()}
                id={"add_categoria_experiencia"}
              />
              <BloqueConfiguracion
                title={"Tipos de actividades"}
                data={webConfiguration.actividades}
                headers={["Nombre", "Acción"]}
                width={"30%"}
                showColumns={["name"]}
                handleInput={addTipoActividad()}
                id={"add_tipo_actividad"}
              />
            </div>
          </>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    getWebConfig();
  }, []);

  return (
    <div className="admin">
      <div className="contentStretch" style={{ paddingTop: "35px" }}>
        <MenuAdministracion />
        <SectionAdministracion />
      </div>
    </div>
  );
}

export default Administracion;
