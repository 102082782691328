import React, { useState } from "react";
import { startOfWeek, addWeeks, format, addDays, parseISO, isSameDay } from "date-fns";
import { es } from "date-fns/locale";
import { demoBooks } from "./demo.js";
import "./style.css";

export const Calendar = () => {
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const goToPreviousWeek = () => {
    setCurrentWeek((prevWeek) => addWeeks(prevWeek, -1));
  };

  const goToNextWeek = () => {
    setCurrentWeek((prevWeek) => addWeeks(prevWeek, 1));
  };

  const renderCalendar = () => {
    const start = startOfWeek(currentWeek);
    const days = [<div key={"experiences-col"} className="calendar-day"></div>];

    for (let i = 0; i < 7; i++) {
      const currentDate = addDays(start, i);
      days.push(
        <div key={i} className="calendar-day">
          {format(currentDate, "E, d MMM", { locale: es })}
        </div>
      );
    }

    return days;
  };

  const renderBookings = () => {
    const start = startOfWeek(currentWeek);
    const experienceBooks = [];

    const getWeekExperienceBooks = (booking) => {
      const weekBooks = [];
      for (let i = 0; i < 7; i++) {
        const currentDate = addDays(start, i);
        weekBooks.push(
          <div className="calendar-bookings">
            {booking.map((b) => {
              const sonIguales = isSameDay(currentDate, b.date);
              const fechaParseada = parseISO(b.date);
              return sonIguales ? (
                <div className="calendar-booking">
                  <div className="calendar-hour">{format(fechaParseada, "HH:mm")}</div>
                  <div className="calendar-experience-name">{b.name}</div>
                </div>
              ) : (
                <div className="calendar-booking-clear"></div>
              );
            })}
          </div>
        );
      }
      return weekBooks;
    };

    experienceBooks.push(
      <div className="">
        {demoBooks.map((experience) => (
          <div className="calendar-experience-week">
            <div className="calendar-experience">{experience.experience}</div>
            {getWeekExperienceBooks(experience.bookings)}
          </div>
        ))}
      </div>
    );

    return experienceBooks;
  };

  return (
    <div className="weekly-calendar">
      <div className="calendar-nav">
        <button onClick={goToPreviousWeek}>Anterior</button>
        <button onClick={goToNextWeek}>Siguiente</button>
      </div>
      <div className="calendar-grid">{renderCalendar()}</div>
      <div className="">{renderBookings()}</div>
    </div>
  );
};
