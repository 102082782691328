export const Aviso = () => {
  return (
    <>
      <p>
        <strong>AVISO LEGAL</strong>
      </p>
      <ol>
        <li>
          <strong>Datos Identificativos. </strong>
        </li>
      </ol>
      <p>
        Este sitio web es titularidad de la empresa Civitrip, S.L. (en adelante,&nbsp;<strong>Empresa)</strong>&nbsp;con domicilio social en Calle
        Juan Valera, n&ordm;8, bajo comercial, 14900 Lucena (C&oacute;rdoba). con CIF B56387566.
      </p>
      <p>Puede contactar con nosotros a trav&eacute;s de la cuenta de correo electr&oacute;nico info@civitrip.com</p>
      <ol>
        <li>
          <strong>Introducci&oacute;n.</strong>
        </li>
      </ol>
      <p>El presente aviso legal regula el uso del servicio del Sitio Web www.civitrip.com</p>
      <p>
        El acceso a la web arriba indicada, as&iacute; como la utilizaci&oacute;n de las funcionalidades (en adelante Servicios) y contenidos, que
        ellas se ofrecen, atribuyen a la persona que los realice la condici&oacute;n de USUARIO. Al navegar por este Sitio Web, usted (en
        adelante,&nbsp;<strong>Usuario</strong>) acepta las presentes condiciones de uso y aviso legal, comprometi&eacute;ndose a su cumplimiento.
      </p>
      <p>
        La empresa podr&aacute; implementar las modificaciones, suspensiones, cancelaciones o restricciones que considere oportunas en el Sitio Web,
        pudiendo cambiar el dise&ntilde;o, presentaci&oacute;n o configuraci&oacute;n del Sitio Web, as&iacute; como los contenidos y servicios
        ofertados, y el contenido del aviso legal y dem&aacute;s condiciones de uso, supedit&aacute;ndose estos cambios a lo establecido en la
        normativa vigente de aplicaci&oacute;n y actuando con total cumplimiento de los derechos e los consumidores y usuarios, en t&eacute;rminos de
        lo establecido en el Real Decretos Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la
        Defensa de los Consumidores y Usuarios y otras leyes complementarias.
      </p>
      <ol>
        <li>
          <strong>Objeto.</strong>
        </li>
      </ol>
      <p>
        Este aviso legal tiene por objeto describir las condiciones legales aplicables al acceso y uso por parte del Usuario a la informaci&oacute;n
        contenida en el Sitio Web, as&iacute; como dar cumplimiento a las obligaciones establecidas en la Ley 34/2002, de Servicios de la Sociedad de
        la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSI-CE).
      </p>
      <p>
        El Usuario queda informado, y acepta, que el acceso al Sitio Web no supone, por s&iacute; mismo, el inicio de una relaci&oacute;n comercial o
        de otro tipo con la Empresa. Para contratar servicios o productos ofertados en el Sitio Web (incluidos servicios gratuitos y promociones)
        deber&aacute; utilizar los canales habilitados al efecto, donde puede requerirse la aceptaci&oacute;n de nuevas condiciones legales, de compra
        o privacidad.
      </p>
      <ol>
        <li>
          <strong>Contenido, exactitud y actualizaci&oacute;n </strong>
        </li>
      </ol>
      <p>
        La empresa, realiza revisiones peri&oacute;dicas de los contenidos e informaci&oacute;n publicados en el Sitio Web, sin embargo, es posible
        que no se tenga constancia de la inexactitud de la informaci&oacute;n o actividad a la que se remiten, por lo que le rogamos que, en caso de
        detectar alguna incoherencia, den traslado inmediato a la organizaci&oacute;n, con el objetivo de proceder con diligencia a la
        supresi&oacute;n o inutilizaci&oacute;n del enlace o contenido correspondiente. La Empresa tampoco puede controlar el empleo que el Usuario da
        a la informaci&oacute;n publicada y por tanto, no ser&aacute; responsable de ning&uacute;n tipo de da&ntilde;os o perjuicios, ya sean directos
        o indirectos, que puedan derivar del empleo de dicha informaci&oacute;n.
      </p>
      <p>
        La Empresa no se hace responsable de las opiniones y respuestas emitidas por terceras personas, ya sea a trav&eacute;s de publicaciones,
        mensajes en redes sociales, respuestas a consultas p&uacute;blicas o comentarios en blogs, publicadas tanto en el Sitio Web como en otro
        website vinculado con &eacute;ste, as&iacute; como en perfiles de la empresa en redes sociales.
      </p>
      <p>
        La empresa realiza un exhaustivo mantenimiento de la p&aacute;gina web, para que el acceso a los servicios y contenidos se encuentre siempre
        disponible. No obstante, el Usuario acepta que puedan existir interrupciones, programadas para el mejorar el rendimiento de la p&aacute;gina o
        con finalidad similar, o accidentales por causas externas a la empresa o que &eacute;sta no pueda controlar.
      </p>
      <ol>
        <li>
          <strong>Propiedad intelectual e industrial</strong>
        </li>
      </ol>
      <p>
        Todos los elementos del Sitio Web tales como su programaci&oacute;n, c&oacute;digo fuente, textos, dise&ntilde;os, presentaci&oacute;n,
        montaje, logotipos, im&aacute;genes, y cualquier otro elemento susceptible de contar con la protecci&oacute;n de los derechos de propiedad
        intelectual o industrial, son titularidad exclusiva de la Empresa o bien dispone de autorizaci&oacute;n o licencia respecto de los titulares
        de sus derechos de explotaci&oacute;n. Todos los nombres comerciales, marcas o signos distintivos, logotipos, s&iacute;mbolos, marcas mixtas,
        figurativas o nominativas que aparecen en este Sitio Web pertenecen a la Empresa o dispone, previa autorizaci&oacute;n, del derecho de uso
        sobre las mismas y se encuentran protegidos por la legislaci&oacute;n vigente al respecto.
      </p>
      <p>
        Salvo en aquellos contenidos en los que expresamente se establezca otra licencia aplicable, la Empresa no concede licencia de uso o
        autorizaci&oacute;n alguna sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con
        el Sitio Web, salvo acuerdo expreso con terceros. Los usuarios podr&aacute;n llevar a cabo la reproducci&oacute;n de los contenidos del Sitio
        Web con el &uacute;nico fin de proceder a su almacenamiento, a la realizaci&oacute;n de copias de seguridad o a su impresi&oacute;n sobre
        papel para uso privado.
      </p>
      <p>
        Al margen de lo anterior, queda prohibida toda reproducci&oacute;n, distribuci&oacute;n, transformaci&oacute;n, presentaci&oacute;n, total o
        parcial, del contenido del Sitio Web o de alguno de sus elementos, de forma directa o indirecta por redes telem&aacute;ticas o soportes
        an&aacute;logos, con un fin comercial dirigido al p&uacute;blico o para una utilizaci&oacute;n m&aacute;s all&aacute; de las mencionadas, aun
        citando la fuente, siempre que no se cuente con la autorizaci&oacute;n expresa y por escrito de la Empresa y en su caso de los terceros
        colaboradores. Quedan especialmente prohibidas:
      </p>
      <ul>
        <li>
          &nbsp;La presentaci&oacute;n de una p&aacute;gina del Sitio Web en una marco de otra p&aacute;gina web que no pertenezca a la Empresa,
          mediante la t&eacute;cnica denominada "framing" o t&eacute;cnica similar, a no ser que cuente con el expreso consentimiento por escrito de
          la Empresa.
        </li>
        <li>
          La inserci&oacute;n de una imagen difundida en el Sitio Web en una p&aacute;gina o base de datos, no perteneciente a la Empresa, mediante la
          t&eacute;cnica denominada "in line linking" o t&eacute;cnica similar, si ello no cuenta con la expresa autorizaci&oacute;n de la Empresa.
        </li>
        <li>
          La extracci&oacute;n y uso de elementos del website causando o no un perjuicio cualquiera a la Empresa, conforme a las disposiciones del
          Real Decreto Legislativo 1/1996, de 12 de abril, por el que se aprueba el Texto Refundido de la Ley de Propiedad Intelectual y la Ley
          5/1998, de 6 de marzo, de Incorporaci&oacute;n al Derecho Espa&ntilde;ol de la Directiva 96/9/CE sobre la Protecci&oacute;n Jur&iacute;dica
          de las Bases de Datos.
        </li>
        <li>
          La Empresa deber&aacute; autorizar expresamente el establecimiento de links de hipertexto (hiperv&iacute;nculos) en otra website dirigidos
          al homepage de este website o a cualquier otra p&aacute;gina interna de &eacute;ste, siempre que las correspondientes p&aacute;ginas
          aparezcan en una ventana completa y bajo las direcciones electr&oacute;nicas propias del mismo.
        </li>
      </ul>
      <p>
        El incumplimiento de lo anterior facultar&aacute; a la Empresa para ejercer cuantas acciones se le reconozcan en Derecho para la defensa de
        sus leg&iacute;timos intereses.
      </p>
      <ol>
        <li>
          <strong>Archivos temporales de informaci&oacute;n</strong>
        </li>
      </ol>
      <p>
        Para el correcto funcionamiento y visualizaci&oacute;n del Sitio Web, la Empresa utiliza archivos temporales de informaci&oacute;n que el
        servidor env&iacute;a al ordenador del usuario. Puede obtener m&aacute;s informaci&oacute;n sobre este extremo en nuestra
        &ldquo;Pol&iacute;tica de Cookies&rdquo;.
      </p>
      <ol>
        <li>
          <strong>Enlaces (links)</strong>
        </li>
      </ol>
      <p>
        Los enlaces externos que puede encontrar en el Sitio Web conducen a otros sitios web titularidad de terceros. La Empresa no responde del
        contenido de estos sitios no garantizando su veracidad o actualizaci&oacute;n. La presencia de estos enlaces en nuestro Sitio Web tiene
        finalidad meramente informativa y en ning&uacute;n caso supone sugerencia, invitaci&oacute;n o recomendaci&oacute;n sobre los mismos.
      </p>
      <ol>
        <li>
          <strong>Legislaci&oacute;n aplicable</strong>
        </li>
      </ol>
      <p>
        Las presentes condiciones se rigen por la legislaci&oacute;n espa&ntilde;ola. Para la resoluci&oacute;n de cualquier controversia o
        cuesti&oacute;n respecto del Sitio Web o de las presentes condiciones legales ser&aacute;n resueltas ante los Juzgados y Tribunales de
        C&oacute;rdoba. En caso de que el Usuario tenga la condici&oacute;n de Consumidor o Usuario de acuerdo con las definiciones del Texto
        Refundido de la Ley General para la Defensa de Consumidores y Usuarios, la resoluci&oacute;n de tales posibles controversias ser&aacute;
        resueltas ante los Juzgados y Tribunales del domicilio del Usuario.
      </p>
      <p>
        Si alguna de las cl&aacute;usulas del presente aviso legal fuera declarada nula o ineficaz, total o parcialmente, no conllevar&aacute; la
        invalidez del resto, manteniendo su vigencia si la Empresa no declarase lo contrario.
      </p>
    </>
  );
};

export const Cookies = () => {
  return (
    <>
      <p>
        <strong>USO DE COOKIES</strong>
      </p>
      <h2>
        <strong>1. Definici&oacute;n y funci&oacute;n de Cookies</strong>
      </h2>
      <p>
        Una cookie es un fichero que nuestra p&aacute;gina Web env&iacute;a a su navegador o dispositivo (smartphone, tableta o televisi&oacute;n
        conectada) y es almacenada por &eacute;ste.&nbsp;Nuestra utiliza cookies al igual que la mayor&iacute;a de sitios en Internet, para almacenar
        informaci&oacute;n sobre su visita.
      </p>
      <p>La finalidad de estas Cookies es:</p>
      <ul>
        <li>
          Garantizar que las p&aacute;ginas web funcionen correctamente, a trav&eacute;s de labores de sesi&oacute;n, es decir, mantener su
          identificaci&oacute;n y mostrar la informaci&oacute;n que usted ha solicitado
        </li>
        <li>
          Seguridad: cuando usted se identifica en nuestra Web, esa identificaci&oacute;n debe ser segura y fiable esto se realiza mediante Cookies.
        </li>
        <li>Conocer su experiencia de navegaci&oacute;n.</li>
        <li>Informaci&oacute;n estad&iacute;stica an&oacute;nima</li>
      </ul>
      <h2>
        <strong>2. Tipo de Cookies que utiliza nuestro sitio web y su finalidad</strong>
      </h2>
      <p>
        Utilizamos Cookies propias y de terceros para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el
        an&aacute;lisis de sus h&aacute;bitos de navegaci&oacute;n. Al continuar navegando por nuestro sitio una vez que ha sido informado el aviso,
        consideramos que acepta su uso. En concreto, los tipos de cookies que utilizamos son:
      </p>
      <ul>
        <li>
          <strong>Indispensables.</strong>&nbsp;Cookies Indispensables, de registro, datos del proceso de compra, permiti&eacute;ndonos gestionar de
          la forma m&aacute;s eficaz posible nuestra oferta dentro de la p&aacute;gina web, adecuando los contenidos mostrados al servicio solicitado
          o al uso de nuestra p&aacute;gina web de alguno de nuestros productos o servicios. Necesarias para que la web funcione correctamente.
        </li>
        <li>
          <strong>Funcionales.</strong>&nbsp;Son las que nos permiten analizar, sin almacenar informaci&oacute;n personal, el n&uacute;mero de visitas
          a nuestro sitio web y realizar mediciones y an&aacute;lisis estad&iacute;sticos, con el objeto de mejorar nuestros servicios. Pueden ser
          tratadas por nosotros o por terceros, como en el caso de Google Analitycs.
        </li>
        <li>
          <strong>Dirigidas.</strong>&nbsp;Estas cookies son las que pueden estar establecidas en nuestro sitio web por nuestros socios publicitarios.
          Pueden ser utilizadas por esas empresas para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios. No almacenan
          directamente informaci&oacute;n personal, sino que se basan en la identificaci&oacute;n &uacute;nica de su navegador y dispositivo de
          Internet.
        </li>
      </ul>
      <table width="105%">
        <thead>
          <tr>
            <td width="13%">
              <p>
                <strong>Tipolog&iacute;a</strong>
              </p>
            </td>
            <td width="16%">
              <p>
                <strong>Propietario</strong>
              </p>
            </td>
            <td width="20%">
              <p>
                <strong>Nombre</strong>
              </p>
            </td>
            <td width="29%">
              <p>
                <strong>Prop&oacute;sitos</strong>
              </p>
            </td>
            <td width="19%">
              <p>
                <strong>M&aacute;s informaci&oacute;n</strong>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="13%">
              <p>Cookies Indispensables</p>
            </td>
            <td width="16%">
              <p>Civitrip, S.L.</p>
            </td>
            <td width="20%">
              <p>
                PHPSESSID
                <br /> acepta_cookies
                <br /> sesion_banner_cupon
                <br /> sesion_akactry
                <br /> referer
                <br /> sesion_encuesta_blog
                <br /> movil
                <br /> pais
                <br /> cd_promo
                <br /> oauth2state
                <br /> continuectry
                <br /> ultimo_curso
                <br /> _ln_visitor
              </p>
            </td>
            <td width="29%">
              <p>
                Adecuar los contenidos mostrados al servicio solicitado o al uso de nuestra p&aacute;gina web de alguno de nuestros productos o
                servicios.
              </p>
            </td>
          </tr>
          <tr>
            <td width="13%">
              <p>Cookies Funcionales</p>
            </td>
            <td width="16%">
              <p>Google</p>
            </td>
            <td width="20%">
              <p>
                _ga,
                <br /> _gat,
                <br /> _gid
              </p>
            </td>
            <td width="29%">
              <p>Analizar el n&uacute;mero de visitas a nuestro sitio web y realizar mediciones y an&aacute;lisis estad&iacute;sticos.</p>
            </td>
            <td width="19%">
              <p>
                <a href="http://www.google.es/intl/es_ALL/analytics/learn/privacy.html">- Centro de privacidad de Google</a>
                <br />
                <a href="http://tools.google.com/dlpage/gaoptout?hl=es">- Complemento de inhabilitaci&oacute;n de Google Analytics</a>
              </p>
            </td>
          </tr>
          <tr>
            <td width="13%">
              <p>Cookies Dirigidas</p>
            </td>
            <td width="16%">
              <p>Facebook</p>
            </td>
            <td width="20%">
              <p>
                _fbq,
                <br /> fr,
                <br /> tr
              </p>
            </td>
            <td width="29%">
              <p>Crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios.</p>
            </td>
          </tr>
          <tr>
            <td width="13%">
              <p>Cookies Dirigidas</p>
            </td>
            <td width="16%">
              <p>Google</p>
            </td>
            <td width="20%">
              <p>IDE</p>
            </td>
            <td width="29%">
              <p>Crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>
        <br />
        <strong>3. C&oacute;mo desactivar las cookies</strong>
      </h2>
      <p>
        Si no desea que instalemos la Cookies necesarias para mejorar su navegabilidad a continuaci&oacute;n le indicamos c&oacute;mo desactivar las
        Cookies en los principales navegadores:
      </p>
      <p>
        <strong>Desactivar Cookies en Google Chrome:</strong>
      </p>
      <ol>
        <li>Haga clic en el men&uacute; de Chrome situado en la barra de herramientas del navegador.</li>
        <li>Haga clic en el bot&oacute;n Herramientas y despu&eacute;s en Opciones de Internet.</li>
        <li>
          Haga clic en la ficha Privacidad, y a continuaci&oacute;n, en Configuraci&oacute;n, mueva el control deslizante totalmente hacia arriba para
          bloquear todas las cookies o totalmente hacia abajo para permitir todas las cookies y, a continuaci&oacute;n, haga clic en Aceptar.
        </li>
        <li>Al bloquear las cookies, puede impedir que ciertos sitios web se muestren correctamente.</li>
      </ol>
      <p>
        <strong>Desactivar Cookies en Firefox:</strong>
      </p>
      <ol>
        <li>En la parte superior de la ventana de Firefox, haz clic en el bot&oacute;n Firefox y selecciona Opciones.</li>
        <li>Selecciona el panel Privacidad.</li>
        <li>Seleccionamos en el apartado &ldquo;Firefox podr&aacute;&rdquo;: Usar una configuraci&oacute;n personalizada para el historial.</li>
        <li>Deseleccionamos el selector Aceptar cookies.</li>
        <li>Hacemos clic en Aceptar.</li>
      </ol>
      <p>
        <strong>Desactivar Cookies en Safari:</strong>
      </p>
      <ol>
        <li>Si quieres desactivar las cookies en el navegador Safari, ve a Preferencias y, en el panel Privacidad, elige Bloquear Cookies</li>
      </ol>
      <p>
        Como se&ntilde;al&aacute;bamos anteriormente, las cookies de finalidad estad&iacute;stica o publicitaria pueden ser tratadas por nosotros o
        por terceros. Es el caso de Google Analytics, un servicio de an&aacute;lisis de estad&iacute;sticas web proporcionado por Google, Inc., una
        compa&ntilde;&iacute;a de Delaware con sede principal en 1600 Amphitheatre Parkway, Mountain View (California), QUE 94043, Estados Unidos
        (&ldquo;Google&rdquo;). Google usar&aacute; esta informaci&oacute;n y nos la presentar&aacute; con el prop&oacute;sito de hacer un seguimiento
        del uso que se hace de nuestra p&aacute;gina web recompilando informes de la actividad del sitio. Google podr&aacute; transmitir dicha
        informaci&oacute;n a terceros cu&aacute;ndo as&iacute; se lo requiera la legislaci&oacute;n, o cu&aacute;ndo terceros procesen la
        informaci&oacute;n por cuenta de Google. La pol&iacute;tica de privacidad de Google puede ser consultada&nbsp;
        <a href="http://www.google.com/intl/eres/policies/privacy/">aqu&iacute;</a>.
      </p>
      <p>
        Nos tomamos muy en serio el respeto a la privacidad y la protecci&oacute;n de los datos personales de nuestros usuarios. A
        continuaci&oacute;n, explicamos el tratamiento que realizamos de estos datos.
      </p>
    </>
  );
};

export const Politica = () => {
  return (
    <>
      <p>
        <strong>POLITICA DE PRIVACIDAD</strong>
      </p>
      <p>
        La presente Pol&iacute;tica de Privacidad establece los l&iacute;mites y par&aacute;metros que&nbsp;Civitrip, S.L. emplea para el uso y
        protecci&oacute;n del conjunto de los datos de car&aacute;cter personal proporcionados por los usuarios de la web &ldquo;civitrip.com&rdquo;,
        comprometi&eacute;ndose a la seguridad de todos ellos y evitar, por tanto, su uso indebido.
      </p>
      <ol>
        <li>
          <strong> Informaci&oacute;n b&aacute;sica sobre protecci&oacute;n de datos</strong>
        </li>
      </ol>
      <ul>
        <li>
          <strong>Responsable:</strong>&nbsp;Civitrip, S.L.
        </li>
        <li>
          <strong>Finalidad:</strong>&nbsp;Informaci&oacute;n y comercial de nuestros servicios digitales.
        </li>
        <li>
          <strong>Legitimaci&oacute;n:</strong>&nbsp;consentimiento del interesado y ejecuci&oacute;n de un contrato en el que el interesado es parte.
        </li>
        <li>
          <strong>Destinatarios:</strong>&nbsp;Encargados del tratamiento para cumplir con las finalidades.
        </li>
        <li>
          <strong>Derechos:</strong>&nbsp;Acceder, rectificar y suprimir los datos, as&iacute; como otros derechos, como se explica en la
          informaci&oacute;n adicional.
        </li>
      </ul>
      <ol start="2">
        <li>
          <strong> Informaci&oacute;n adicional</strong>
        </li>
      </ol>
      <ul>
        <li>
          <strong>Raz&oacute;n Social:</strong>&nbsp;Civitrip, S.L.
        </li>
        <li>
          <strong>C.I.F.:</strong>&nbsp;B56387566.
        </li>
        <li>
          <strong>Domicilio:</strong>&nbsp;Calle Juan Valera, n&ordm;8, bajo-comercial, C.P. 14.900, Lucena (C&oacute;rdoba)
        </li>
        <li>
          <strong>Informaci&oacute;n adicional:</strong>&nbsp;puede consultar informaci&oacute;n adicional a trav&eacute;s del correo
          electr&oacute;nico:&nbsp;info@civitrip.com
        </li>
        <li>
          <strong>Datos de Contacto del Delegado de Protecci&oacute;n de Datos (DPO):</strong>&nbsp;info@civitrip.com
        </li>
      </ul>
      <ol start="3">
        <li>
          <strong> Normativa aplicable</strong>
        </li>
      </ol>
      <p>
        Nuestra Pol&iacute;tica de Privacidad se ha dise&ntilde;ado conforme a la normativa legal vigente seg&uacute;n el Reglamento General de
        Protecci&oacute;n de Datos de la UE 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de
        las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos y por el que
        se deroga la Directiva 95/46/CE (Reglamento general de protecci&oacute;n de datos) y la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de
        Protecci&oacute;n de Datos Personales y Garant&iacute;a de los Derechos Digitales.
      </p>
      <p>
        Al facilitarnos sus datos, usted declara haber le&iacute;do y conocer la presente Pol&iacute;tica de Privacidad, prestando su consentimiento
        de forma libre, espec&iacute;fica, informada e inequ&iacute;voca al tratamiento de sus datos personales de acuerdo con las finalidades y
        t&eacute;rminos aqu&iacute; expresados.
      </p>
      <p>
        Estas condiciones de privacidad podr&aacute;n ser complementadas por el Aviso Legal, Pol&iacute;tica de Cookies y las Condiciones Generales
        que, en su caso, se recojan para determinados productos o servicios, si dicho acceso supone alguna especialidad en materia de
        protecci&oacute;n de datos de car&aacute;cter personal.
      </p>
      <ol start="4">
        <li>
          <strong> Finalidades del tratamiento y bases legitimadoras</strong>
        </li>
      </ol>
      <p>
        En el presente apartado se tipifican las distintas finalidades y bases legitimadoras para el tratamiento de datos de car&aacute;cter personal
        llevados a cabo por&nbsp;<strong>Civitrip,&nbsp;S.L.</strong>
      </p>
      <ul>
        <li>Consentimiento del interesado (art. 6.1.a) RGPD):</li>
        <li>Gestionar el env&iacute;o de la informaci&oacute;n que usted nos solicite sobre nuestra empresa y nuestros productos.</li>
        <li>
          Facilitar a los interesados informaci&oacute;n comercial sobre nuestros productos, que podr&aacute; recibir por diversas v&iacute;as,
          especialmente tel&eacute;fono y correo electr&oacute;nico.
        </li>
        <li>
          Enviarle nuestra newsletter con las publicaciones en nuestros blogs y las novedades acerca de nuestra entidad. Gesti&oacute;n de comentarios
          en nuestros foros y blogs.
        </li>
        <li>Cumplimiento de una obligaci&oacute;n legal (art&iacute;culo 6.1.c) RGPD):</li>
        <li>
          Comunicar informaci&oacute;n a autoridades p&uacute;blicas, reguladoras u &oacute;rganos gubernamentales en aquellos supuestos en que sea
          necesario hacerlo por ley, normativa local o en el cumplimiento de obligaciones regulatorias.
        </li>
        <li>Inter&eacute;s leg&iacute;timo del responsable del tratamiento (art&iacute;culo 6.1.f) RGPD):</li>
        <li>
          Env&iacute;o de publicidad e informaci&oacute;n comercial sobre los servicios ofrecidos por&nbsp;<strong>Civitrip,&nbsp;S.L.</strong> por
          cualquier v&iacute;a, incluida la electr&oacute;nica, siendo esta publicidad propia (en relaci&oacute;n con el art&iacute;culo 21.2 LSSICE).
        </li>
        <li>
          Tratamiento de los datos de la persona de contacto de una empresa, o personas f&iacute;sicas, a efectos de localizaci&oacute;n profesional y
          en funci&oacute;n de la prestaci&oacute;n de los servicios que ofrecen (en relaci&oacute;n con el art&iacute;culo 19 LOPDGDD).
        </li>
        <li>
          Para la mejora de la web y su funcionamiento, se elaboran estad&iacute;sticas, recabamos informaci&oacute;n de la navegaci&oacute;n del
          usuario en fecha y hora de acceso y as&iacute; mejoramos la experiencia del usuario en la web.
        </li>
      </ul>
      <ol start="5">
        <li>
          <strong> &iquest;A qu&eacute; destinatarios comunicaremos sus datos?</strong>
        </li>
      </ol>
      <p>
        Sus datos personales podr&aacute;n ser facilitados a empresas colaboradoras y participadas del Responsable de tratamiento cuando sea necesario
        para cumplir con las finalidades anteriormente descritas para que &eacute;stas puedan facilitar por diversos medios informaci&oacute;n sobre
        productos y servicios relacionados con la formaci&oacute;n, el empleo, productos editoriales y las nuevas tecnolog&iacute;as,
        respet&aacute;ndose en todos los casos las medidas de seguridad exigidas por la legislaci&oacute;n vigente en materia de protecci&oacute;n de
        datos de car&aacute;cter personal, as&iacute; como las pol&iacute;ticas generales de calidad y privacidad de la Empresa.
      </p>
      <p>
        A excepci&oacute;n de las previsiones contenidas en el p&aacute;rrafo anterior,&nbsp;<strong>Civitrip,&nbsp;S.L</strong> no realizar&aacute;
        comunicaciones de los datos del Usuario a terceros m&aacute;s all&aacute; de las exigidas en la normativa vigente en materia de
        protecci&oacute;n de datos que las realizadas a las autoridades competentes cuando le fuera requerida.
      </p>
      <ol start="6">
        <li>
          <strong> &iquest;Se realizan Transferencias Internacionales de Datos?</strong>
        </li>
      </ol>
      <p>
        <strong>Civitrip,&nbsp;S.L</strong>. no realizar&aacute; transferencias internacionales de sus datos.
      </p>
      <ol start="7">
        <li>
          <strong> &iquest;Durante qu&eacute; plazo conservaremos sus datos?</strong>
        </li>
      </ol>
      <p>
        Los datos personales proporcionados se conservar&aacute;n mientras no se solicite su supresi&oacute;n por el interesado. En todo caso, Sus
        datos personales ser&aacute;n conservados durante el plazo necesario para el cumplimiento efectivo de la finalidad para los que fueron
        recabados y durante el tiempo establecido por la normativa legal vigente, salvo revocaci&oacute;n del consentimiento o ejercicio del derecho a
        la limitaci&oacute;n del tratamiento. En tales casos, sus datos se bloquear&aacute;n durante los plazos legalmente establecidos.
      </p>
      <ol start="8">
        <li>
          <strong> &iquest;Qu&eacute; medidas de seguridad aplicaremos?</strong>
        </li>
      </ol>
      <p>
        <strong>Civitrip,&nbsp;S.L.</strong> y las empresas colaboradoras y participadas tratar&aacute;n sus datos personales bajo la m&aacute;s
        estricta confidencialidad, aplicando las medidas de seguridad, t&eacute;cnicas y organizativas fijadas en la legislaci&oacute;n aplicable.
      </p>
      <ol start="9">
        <li>
          <strong> &iquest;Cu&aacute;les son los derechos que puede ejercer?</strong>
        </li>
      </ol>
      <p>El Usuario tiene los siguientes derechos en cuanto al tratamiento de sus datos personales:</p>
      <ul>
        <li>Derecho a solicitar el acceso a sus datos personales.</li>
        <li>
          Derecho a solicitar su rectificaci&oacute;n si son inexactos, o bien solicitar su supresi&oacute;n (por ejemplo, si considera que ya no son
          necesarios para los fines que fueron recogidos).
        </li>
        <li>
          Derecho a solicitar la limitaci&oacute;n de su tratamiento, siempre que se cumpla alguna de las condiciones previstas en la normativa, en
          cuyo caso &uacute;nicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
        </li>
        <li>
          Derecho a oponerse al tratamiento, en cuyo caso dejaremos de tratar sus datos, salvo por motivos leg&iacute;timos imperiosos, o el ejercicio
          o la defensa de posibles reclamaciones.
        </li>
        <li>Derecho a la portabilidad de los datos.</li>
        <li>
          Derecho a revocar el consentimiento para cada finalidad espec&iacute;fica, sin que ello afecte a la licitud del tratamiento basado en el
          consentimiento previo a su retirada.
        </li>
        <li>
          Derecho a presentar una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos (autoridad de control en materia de
          Protecci&oacute;n de Datos competente), especialmente cuando no haya obtenido satisfacci&oacute;n en el ejercicio de sus derechos: Reclamar
        </li>
      </ul>
      <p>
        Asimismo, y de acuerdo con lo establecido en la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del
        Comercio Electr&oacute;nico, en el supuesto de que usted no desee recibir comunicaciones comerciales electr&oacute;nicas en el futuro,
        podr&aacute; manifestar tal deseo enviando un mail a la siguiente direcci&oacute;n de correo electr&oacute;nico info@civitrip.com
      </p>
      <ol start="10">
        <li>
          <strong> &iquest;C&oacute;mo puede ejercer sus derechos?</strong>
        </li>
      </ol>
      <p>
        Disponemos de formularios para el ejercicio de sus derechos que podr&aacute; solicitarnos a trav&eacute;s de la siguiente direcci&oacute;n de
        correo electr&oacute;nico: info@civitrip.com
      </p>
      <p>Estos formularios deben ir firmados electr&oacute;nicamente o acompa&ntilde;ados de la fotocopia del DNI o Pasaporte en vigor.</p>
      <p>
        Estos formularios pueden ser presentados de forma presencial en Calle Juan Valera, n&ordm;8, bajo comercial, C.P. 14900, Lucena
        (C&oacute;rdoba) o a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico: info@civitrip.com.
      </p>
      <ol start="11">
        <li>
          <strong> &iquest;Cu&aacute;l es el tiempo establecido para dar soluci&oacute;n al ejercicio de derechos?</strong>
        </li>
      </ol>
      <p>
        Le informaremos sobre las actuaciones derivadas de su petici&oacute;n en el plazo de un mes que podr&aacute; extenderse dos meses m&aacute;s
        cuando se trate de solicitudes especialmente complejas y le notificaremos dicha ampliaci&oacute;n dentro del primer mes.
      </p>
      <p>
        En aquellos casos que no atendamos su solicitud, le informaremos de ello, motivando su negativa dentro del plazo de un mes desde su
        presentaci&oacute;n.
      </p>
      <ol start="12">
        <li>
          <strong> &iquest;C&oacute;mo hemos obtenido sus datos personales?</strong>
        </li>
      </ol>
      <p>
        Obtenemos la informaci&oacute;n que nos facilita rellenado los distintos formularios que aparecen en la web marcando la casilla &ldquo;
        <em>Acepto la Pol&iacute;tica de Privacidad</em>&rdquo; y hacer clic para enviar los datos, o al remitir correos electr&oacute;nicos o
        WhatsApp a la Empresa a trav&eacute;s de las cuentas habilitadas al efecto, el Usuario manifiesta haber le&iacute;do y aceptado expresamente
        la presente pol&iacute;tica de privacidad, y otorga su consentimiento inequ&iacute;voco y expreso al tratamiento de sus datos personales
        conforme a las finalidades informadas.
      </p>
      <p>
        De acuerdo con las finalidades indicadas en el apartado 2, las categor&iacute;as de datos que ser&aacute;n objeto de tratamiento son las
        siguientes:
      </p>
      <ul>
        <li>Datos identificativos: nombre y apellidos, tel&eacute;fono y correo electr&oacute;nico.</li>
      </ul>
      <p>
        <strong>12.1. &iquest;Qu&eacute; ocurre si no los facilito?</strong>
      </p>
      <p>
        Los datos de car&aacute;cter obligatorio se distinguir&aacute;n en los formularios de recogida de informaci&oacute;n. Si usted decide no
        proporcionarnos alguno de esos datos considerados obligatorios no podremos cumplir por con la finalidad prevista y no se realizar&aacute; el
        tratamiento de datos ni la prestaci&oacute;n del servicio correspondiente. En caso de elaboraci&oacute;n de un &ldquo;
        <em>perfil comercial</em>&rdquo; en base a la informaci&oacute;n facilitada, no se tomar&aacute;n decisiones automatizadas con efectos
        jur&iacute;dicos para el Usuario.
      </p>
      <p>
        Usted garantiza que los Datos Personales que nos facilite son veraces y correctos, y ser&aacute; responsable de comunicarnos cualquier
        modificaci&oacute;n en los mismos. En el caso de que los datos aportados pertenecieran a un tercero, el Usuario garantiza que ha informado a
        dicho tercero de los aspectos contenidos en este documento y obtenido su autorizaci&oacute;n para facilitar sus datos.
      </p>
      <ol start="13">
        <li>
          <strong> Redes sociales</strong>
        </li>
      </ol>
      <p>
        Los perfiles en redes sociales con los que cuente&nbsp;<strong>Civitrip, S.L.</strong> no supondr&aacute;n un tratamiento de datos m&aacute;s
        all&aacute; del que la propia red social permita para perfiles corporativos. La Empresa podr&aacute; utilizar dichos perfiles para informar a
        los usuarios de cada red social suscritos al perfil de la empresa sobre sus actividades, eventos, seminarios, ofertas, promociones o novedades
        en sus productos o servicios, as&iacute; como compartir informaci&oacute;n de inter&eacute;s sobre las tem&aacute;ticas habituales de la
        empresa. La Empresa no extraer&aacute; ning&uacute;n dato directamente de la red social.
      </p>
    </>
  );
};
