import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { Input, Select, OptionsGroup, InputWOptions } from "../components/forms";

//Reducers
import { setUserConfig, setAdmSectionActive } from "./../reducers/userConfigSlice";
//API
import { postProveedor, getLogin } from "./../api/requests";
import { getCountries, getCountriesStates } from "./../api/locations";
import "./login.css";

function Login() {
  const [formType, setFormType] = useState("login");
  const [countrySelected, setCountrySelected] = useState("España");
  const [countries, setCountries] = useState({});
  const [countriesStates, setCountriesStates] = useState({});
  const [tipoCuenta, setTipoCuenta] = useState("cliente");
  const [alert, setAlert] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTipoCuenta = (value) => {
    setTipoCuenta(value);
  };

  const handleLogin = async () => {
    console.log("handleLogin");
    const data = {
      email:
        document.getElementById("login_email") === null
          ? document.getElementById("register_email").value
          : document.getElementById("login_email").value,
      clave:
        document.getElementById("login_password") === null
          ? base64_encode(document.getElementById("register_password").value)
          : base64_encode(document.getElementById("login_password").value),
    };
    const response = await getLogin(data);
    console.log(response);
    if (response.id) {
      dispatch(setUserConfig({ logged: true, userData: response }));
      if (response.rol === "proveedor") {
        dispatch(setAdmSectionActive("dashboard-provider"));
        navigate("/proveedores");
      }
      if (response.rol === "administrador") {
        navigate("/administracion");
      }
      if (response.rol === "cliente") {
        dispatch(setAdmSectionActive("vouchers-client"));
        navigate("/clientes");
      }
      setAlert("");
    } else {
      if (response.status === 500) {
        setAlert("Usuario o contraseña inválidos");
      }
    }
  };

  const handleFormType = (type) => {
    setFormType(type);
    window.scrollTo(0, 0);
  };

  const handleState = (e) => {
    console.log(e);
    console.log(document.getElementById(e).value);
    setCountrySelected(document.getElementById(e).value);
    console.log(
      countriesStates
        .filter((c_s) => c_s.native === document.getElementById(e).value)[0]
        .states.map((s) => {
          return { id: s.id, value: s.name };
        })
    );
  };

  const addAccount = async () => {
    const data = {
      email: document.getElementById("register_email").value,
      clave: base64_encode(document.getElementById("register_password").value),
      nombre: document.getElementById("register_nombre").value,
      apellidos: document.getElementById("register_apellidos").value,
      razonsocial: document.getElementById("register_razonsocial") === null ? "" : document.getElementById("register_razonsocial").value,
      telefono: document.getElementById("register_telefono").value,
      actividades: Array.prototype.slice
        .call(document.querySelectorAll("input[name=register_actividades]:checked"))
        .map((el) => el.value)
        .toString(),
      destinos: Array.prototype.slice
        .call(document.getElementsByClassName("class_register_destino"))
        .map((d) => d.innerHTML)
        .toString(),
      web: document.getElementById("register_web") === null ? "" : document.getElementById("register_web").value,
      estadolegal: document.getElementById("register_estadolegal").value,
      dnicif: document.getElementById("register_dnicif").value,
      direccionfiscal: document.getElementById("register_direccionfiscal") === null ? "" : document.getElementById("register_direccionfiscal").value,
      ciudad: document.getElementById("register_ciudad").value,
      cp: document.getElementById("register_cp").value,
      region: document.getElementById("register_region").value,
      pais: document.getElementById("register_pais").value,
      moneda: document.getElementById("register_moneda").value,
      rol: tipoCuenta,
    };
    const response = await postProveedor(data);
    if (response.nombre) {
      console.log(response.nombre);
      console.log(response.nombre ? true : false);
      handleLogin();
    }
  };

  const LoginForm = () => {
    return (
      <div className="admin">
        <div className="contentStretch" style={{ paddingTop: "35px" }}>
          <div className="section login_form">
            <p className="login_title">Identifícate</p>
            <Input label="Email" id="login_email" suggestions={[]} explanation={"Lo usarás para identificarte en tu cuenta"} handleInput={null} />
            <Input
              label="Clave"
              id="login_password"
              suggestions={[]}
              explanation={"Mínimo 8 carácteres incluyendo números, mayúsculas y minúsculas"}
              handleInput={null}
            />
            <button className="button" onClick={handleLogin}>
              Entrar
            </button>
            <div className="alert">{alert}</div>
            <div className="link" onClick={() => handleFormType("registerProveedor")} style={{ textAlign: "center", marginTop: "25px" }}>
              Solicitar alta nueva de proveedor
            </div>
            <div className="link" onClick={() => handleFormType("registerClient")} style={{ textAlign: "center", marginTop: "25px" }}>
              Solicitar alta nueva de cliente
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RegisterForm = () => {
    return (
      <div className="admin">
        <div className="contentStretch" style={{ paddingTop: "35px" }}>
          <div className="section login_form">
            <p className="login_title">Tipo de cuenta</p>
            <p style={{ display: "inline-flex", width: "100%", textAlign: "left" }}>
              <input
                type="radio"
                name="usertype"
                style={{ width: "40px" }}
                value="cliente"
                onClick={() => handleTipoCuenta("cliente")}
                checked={tipoCuenta === "cliente"}
              />{" "}
              Cliente
              {/*<input
                type="radio"
                name="usertype"
                style={{ width: "40px" }}
                value="proveedor"
                onClick={() => handleTipoCuenta("proveedor")}
                checked={tipoCuenta === "proveedor"}
              />{" "}
    Proveedor*/}
            </p>
            <p className="login_title">Contacto principal {tipoCuenta}</p>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div className="sub-section-2">
                <Input label="Nombre" id="register_nombre" suggestions={[]} explanation={""} handleInput={null} />
              </div>
              <div className="sub-section-2">
                <Input label="Apellidos" id="register_apellidos" suggestions={[]} explanation={""} handleInput={null} />
              </div>
            </div>
            {tipoCuenta === "proveedor" && (
              <Input label="Razón social" id="register_razonsocial" suggestions={[]} explanation={""} handleInput={null} />
            )}
            <Input label="Email" id="register_email" suggestions={[]} explanation={"Lo usarás para identificarte en tu cuenta"} handleInput={null} />
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div className="sub-section-2">
                <Input
                  label="Clave"
                  id="register_password"
                  suggestions={[]}
                  explanation={"Mínimo 8 carácteres incluyendo números, mayúsculas y minúsculas"}
                  handleInput={null}
                />
              </div>
              <div className="sub-section-2">
                <Input label="Teléfono" id="register_telefono" suggestions={[]} explanation={""} handleInput={null} />
              </div>
            </div>
            {tipoCuenta === "proveedor" && (
              <>
                <p className="login_title" style={{ paddingTop: "20px" }}>
                  Datos de servicios
                </p>
                <OptionsGroup
                  label={"¿Qué tipo de actividades ofertas?"}
                  name="register_actividades"
                  options={[
                    { id: 1, name: "Actividades deportivas" },
                    { id: 2, name: "Alquiler de vehículos" },
                    { id: 3, name: "Cruceros" },
                    { id: 4, name: "Hop-on Hop-off" },
                    { id: 5, name: "Guias turísticos" },
                    { id: 6, name: "Spas" },
                    { id: 7, name: "Experiencias gastronómicas" },
                  ]}
                />
                <InputWOptions
                  label="Destinos"
                  id="register_destino"
                  suggestions={[{ name: "Córdoba" }, { name: "Sevilla" }]}
                  explanation={"Destinos donde operas"}
                />
                <Input label="Agrega tu web o tu principal red social" id="register_web" suggestions={[]} explanation={""} handleInput={null} />
              </>
            )}
            <p className="login_title" style={{ paddingTop: "20px" }}>
              Detalles de la cuenta
            </p>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div className="sub-section-2">
                <Select
                  label="Estado legal"
                  id="register_estadolegal"
                  data={[
                    { id: 1, value: "Empresa" },
                    { id: 2, value: "Autónomo" },
                    { id: 3, value: "Particular" },
                  ]}
                />
              </div>
              <div className="sub-section-2">
                <Input label="CIF/DNI" id="register_dnicif" suggestions={[]} explanation={""} handleInput={null} />
              </div>
            </div>
            {tipoCuenta === "proveedor" && (
              <Input label="Dirección fiscal" id="register_direccionfiscal" suggestions={[]} explanation={""} handleInput={null} />
            )}
            <Input
              label="Pais"
              id="register_pais"
              suggestions={countries.map((c) => {
                return { id: c.id, name: c.native };
              })}
              explanation={""}
              handleInput={handleState}
            />

            <div style={{ display: "inline-flex", width: "100%" }}>
              <div className="sub-section-2">
                <Input
                  label="Ciudad"
                  id="register_ciudad"
                  suggestions={countriesStates
                    .filter((c_s) => c_s.native === countrySelected)[0]
                    .states.map((s) => {
                      return { id: s.id, name: s.name };
                    })}
                  explanation={""}
                  handleInput={() => null}
                />
              </div>
              <div className="sub-section-2">
                <Input label="Código postal" id="register_cp" suggestions={[]} explanation={""} handleInput={null} />
              </div>
            </div>
            <Input label="Región" id="register_region" suggestions={[]} explanation={"Comunidad autónoma o región"} handleInput={null} />
            <Select
              label="Moneda"
              id="register_moneda"
              data={[
                { id: 1, value: "Euro" },
                { id: 2, value: "Dolar" },
              ]}
            />
            <p></p>
            <button className="button" onClick={() => addAccount()}>
              Solicitar
            </button>
            <div className="link" onClick={() => handleFormType("login")} style={{ textAlign: "center", marginTop: "25px" }}>
              Ya tengo cuenta
            </div>
          </div>
        </div>
      </div>
    );
  };

  const OnBoardingForm = () => {
    return (
      <div className="rowContent infoBlockProduct">
        <div className="contentStretch">
          <iframe
            className="onBoardingForm"
            src="https://forms.zohopublic.eu/info1405/form/DatosdelaEmpresaExperienciasCIVITRIP/formperma/nqJbUcGf6PHEkw4HwIRrj6yeaqanxkBotnMP9VBqBEM?zf_enablecamera=true"
            title="onBoardingForm"
          ></iframe>
        </div>
      </div>
    );
  };

  const loadRequiredData = async () => {
    let responseCountries = await getCountries();
    if (responseCountries) setCountries(responseCountries);

    let responseCountriesStates = await getCountriesStates();
    if (responseCountriesStates) setCountriesStates(responseCountriesStates);
  };

  const FormType = () => {
    let form = <LoginForm />;
    if (formType === "login") form = <LoginForm />;
    if (formType === "registerProveedor") form = <OnBoardingForm />;
    if (formType === "registerClient") form = <RegisterForm />;
    return form;
  };

  useEffect(() => {
    loadRequiredData();
    setFormType("login");
    setTipoCuenta("cliente");
  }, []);

  return <FormType />;
}

export default Login;
