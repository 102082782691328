import React from "react";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

export default function BarComponent({ data }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />

        <Legend />
        <Bar dataKey="reservas" radius={[10, 10, 0, 0]} fill="#0088FE" activeBar={<Rectangle fill="pink" stroke="blue" />} />
        <Bar dataKey="ventas" radius={[10, 10, 0, 0]} fill="#150088" activeBar={<Rectangle fill="gold" stroke="purple" />} />
      </BarChart>
    </ResponsiveContainer>
  );
}
