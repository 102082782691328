import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setAdmSectionActive, setUserConfig, setAdmExperienceSelected } from "../../reducers/userConfigSlice";
//Components
import { ModalComponent } from "../../components/modal";
import { FiMenu } from "react-icons/fi";
import { GoDeviceDesktop, GoOrganization, GoInbox, GoSignOut, GoPerson, GoMilestone, GoCreditCard, GoCalendar } from "react-icons/go";
//Styles
import "./style.css";
import iconFavorite from "./../../assets/icons/favorite.svg";
import iconLocation from "./../../assets/icons/location.svg";
import iconCart from "./../../assets/icons/shopping.svg";
import { SearchComponent } from "../../components/searchComponent";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const nombre = useSelector((state) => state.aplicationConfig.userConfig.userData.nombre);
  const rol = useSelector((state) => state.aplicationConfig.userConfig.userData.rol);
  const pageActive = useSelector((state) => state.aplicationConfig.pageActive);
  const [miniCart, setMiniCart] = useState(true);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  //Modal control
  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => setOpenModal(false);

  const navigateAuthPanel = (section) => {
    if (rol === "cliente") {
      dispatch(setAdmSectionActive(section));
      navigate("/clientes");
    } else if (rol === "proveedor") {
      dispatch(setAdmSectionActive(section));
      dispatch(setAdmExperienceSelected(null));
      navigate("/proveedores");
    }
  };

  const logout = () => {
    dispatch(setUserConfig({ logged: false, userData: {} }));
    navigate("/login");
  };

  const MenuDesktop = () => {
    return (
      <div className="menuDesktop">
        {!logged && (
          <>
            <div className="menuItemDesktop" onClick={() => null}>
              <img src={iconLocation} alt="proveedor" />
              <div onClick={() => navigate("/proveedores")}>Hazte proveedor</div>
            </div>

            <div className="menuItemDesktop" onClick={() => navigate("/wishlists")}>
              <img src={iconFavorite} alt="proveedor" />
              <div>Favoritos</div>
            </div>
            <div className="menuItemDesktop" onClick={handleModal}>
              <img src={iconCart} alt="carrito" />
              <div>Carrito</div>
            </div>

            <div className="menuItemDesktop" onClick={() => navigate("/login")}>
              <GoPerson size={25} /> <div>Login</div>
            </div>
          </>
        )}
        {logged && (
          <>
            {rol === "proveedor" && (
              <>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("dashboard-provider")}>
                  <GoDeviceDesktop size={25} /> <div>Escritorio</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("bookings-provider")}>
                  <GoCalendar size={25} /> <div>Reservas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("sales-provider")}>
                  <GoInbox size={25} /> <div>Ventas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("experiences-provider")}>
                  <GoMilestone size={25} /> <div>Experiencias</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("bussiness-provider")}>
                  <GoOrganization size={25} /> <div>Empresa</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("profile")}>
                  <GoPerson size={25} />
                  {<div>{nombre}</div>}
                </div>
                <div className="menuItemDesktop" onClick={() => logout()}>
                  <GoSignOut size={25} color={"darkred"} /> <div style={{ color: "darkred" }}>Salir</div>
                </div>
              </>
            )}
            {rol === "cliente" && (
              <>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("vouchers-client")}>
                  <GoMilestone size={25} /> <div>Reservas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("billing-client")}>
                  <GoCreditCard size={25} /> <div>Facturación</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("profile")}>
                  <GoPerson size={25} />
                  {<div>{nombre}</div>}
                </div>
                <div className="menuItemDesktop" onClick={() => logout()}>
                  <GoSignOut size={25} color={"darkred"} /> <div style={{ color: "darkred" }}>Salir</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const handleMenuMobile = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  //Modal components

  const handleModal = () => {
    console.log("handleModal");
    setOpenModal(!openModal);
  };

  const ContentIframe = () => {
    return (
      <iframe
        className="embebbedIframe"
        src="https://widget.ticando.net/PIiQLCCxZENIrchKojAvFGvyOItQOAGcIosTquRQydYqGGBupBquHltVtnmIoskvheQYZg/cart"
        title="Carrito"
      ></iframe>
    );
  };

  const MenuMobile = () => {
    return (
      <div className="menu menuMobile">
        <FiMenu style={{ fontSize: "30px", padding: "30px" }} onClick={handleMenuMobile} />
        {showMenuMobile === true && (
          <div className="menuMobileContainer">
            <div className="menuItem link" onClick={() => navigate("/wishlists")}>
              <img src={iconFavorite} alt="proveedor" />
              <div>Favoritos</div>
            </div>
            <div className="menuItem link" onClick={handleModal}>
              <img src={iconCart} alt="carrito" />
              <div>Carrito</div>
            </div>
            <div className="menuItem link" onClick={() => navigate("/login")}>
              <GoPerson size={25} /> <div>Login</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="rowContent header">
      <div className="contentStretch d-flex justify-content-between">
        <div className="logo link">
          <div onClick={() => navigate("/")}>
            <img src="https://civitrip.com/wp-content/uploads/2023/06/logo-civitrip-web.png.webp" alt="logo" width="75%" />
          </div>
        </div>
        {pageActive === "experience" && (
          <div className="searchHeader">
            <SearchComponent experiencias={[]} showButton={false} />
          </div>
        )}
        <MenuDesktop />
        <MenuMobile />
        {openModal && <ModalComponent openModal={openModal} handleClose={handleClose} content={<ContentIframe />} />}
      </div>
    </div>
  );
};

export default Header;
