import React from "react";
import GoogleMapReact from "google-map-react";
import { FaMapPin } from "react-icons/fa";

const API_KEY = "AIzaSyDV4y2EtmJwwm6WMP0l_tJBlZRxhMQHv2c";

export const GoogleMap = ({ lat, lng, callback = () => null }) => {
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: API_KEY }}
      defaultCenter={{ lat: lat, lng: lng }}
      zoom={15}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      onClick={(e) => {
        callback({ lat: e.lat, lng: e.lng });
      }}
      disableDoubleClickZoom={true}
      scrollwheel={false}
      zoomControl={false}
    >
      <FaMapPin lat={lat} lng={lng} text="My Marker" style={{ fontSize: "50px", color: "#0140de" }} />
    </GoogleMapReact>
  );
};
