import { useState } from "react";
import StarRatings from "react-star-ratings";
import "./style.css";

export const Review = ({ data }) => {
  const ReviewComponent = ({ review }) => {
    const [expandido, setExpandido] = useState(false);

    const toggleExpandido = () => {
      setExpandido(!expandido);
    };
    return (
      <div className="review">
        <div className="review-header">
          <div className="review-header-left">
            <div className="review-header-icon">{review.name[0]}</div>
            <div className="review-header-name">{review.name}</div>
          </div>
          <div className="review-header-stars">
            <StarRatings rating={review.rating} numberOfStars={5} name="rating" starRatedColor={"gold"} starDimension={"20px"} starSpacing={"2px"} />
          </div>
        </div>
        <div
          className="review-body"
          style={{
            maxHeight: expandido ? "none" : "120px",
            overflow: "hidden",
            transition: "max-height 0.3s ease",
          }}
        >
          {review.text}
        </div>
        <div className="review-handleOpen" onClick={toggleExpandido}>
          {expandido ? "Ver menos" : "Ver más"}
        </div>
      </div>
    );
  };

  return (
    <div className="row">
      {data.map((review) => (
        <div className="col-md-12">
          <ReviewComponent review={review} />
        </div>
      ))}
    </div>
  );
};
