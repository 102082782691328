export const parseExperienceValues = (values, data) => {
  let arrayData = [];
  values.map((val) => {
    if (data && data.length > 0) {
      let experience = data.filter((f) => f.id === val.experience);
      if (experience && experience.length > 0) {
        let photos = experience[0].photos.split(",");
        if (photos && photos.length > 0) {
          arrayData.push({
            experience: (
              <div>
                <img src={photos[0]} width="75px" alt="experience" />
                {experience[0].shortdesc}
              </div>
            ),
            date: val.date,
            value: val.value,
          });
        }
      }
    }
  });
  return arrayData;
};
