import React, { useState, useEffect } from "react";
import Card from "../../components/card";
import "./styles.css";

export const PaginationList = ({ data, resultPerPage }) => {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);

  const moreResults = () => {
    const nextPage = page + 1;
    console.log(data.slice(0, resultPerPage * nextPage));
    setResults(data.slice(0, resultPerPage * nextPage));
    setPage(page);
    window.scrollTo(0, window.scrollY + 400);
  };

  useEffect(() => {
    setResults(data.slice(0, resultPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", paddingBottom: "80px" }}>
        {results.map((e, index) => {
          return <Card key={"exp" + index} e={e} />;
        })}
      </div>
      {data.length > results.length && (
        <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", paddingBottom: "80px" }}>
          <div className="moreSeparator"></div>
          <div className="moreButton" onClick={moreResults}>
            Ver mas
          </div>
          <div className="moreSeparator"></div>
        </div>
      )}
    </>
  );
};
