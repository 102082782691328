import React, { useState } from "react";
//Reducers
import { setUserConfig, setAlert } from "../../../../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import CardLarge from "../../../../components/cardLarge";
//API

export const Sales = () => {
  const experiencias = useSelector((state) => state.aplicationConfig.proveedorConfig.experiencias);
  const mockSalesIds = [52, 52, 54, 51, 51, 54];
  return (
    <>
      <div className="section">
        <div className="row">
          {mockSalesIds.map((sale, index) => {
            let e = experiencias.filter((f) => f.id === sale)[0];
            return (
              <div className="col-12">
                <CardLarge key={"exp" + index} e={e} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
