import React from "react";
import { Input } from "../forms";
import { FiX, FiAirplay } from "react-icons/fi";
import "./style.css";

export const BloqueConfiguracion = ({ data, headers, title, width, showColumns, handleInput, id }) => {
  return (
    <div className="bloqueConfiguracion" style={{ width: width }}>
      <div className="bloqueConfiguracion-title">{title}</div>
      <div className="bloqueConfiguracion-resultados">
        <div className="bloqueConfiguracion-resultados-row">
          {headers.map((h, index) => {
            return (
              <div className={index === 0 ? "bloqueConfiguracion-resultados-name" : "bloqueConfiguracion-resultados-col"}>
                <b>{h}</b>
              </div>
            );
          })}
        </div>
        {data.map((c) => {
          return (
            <div className="bloqueConfiguracion-resultados-row">
              {showColumns.map((key) => {
                if (key !== "id") {
                  if (key === "name") return <div className="bloqueConfiguracion-resultados-name">{c[key]}</div>;
                  else if (key === "portada") {
                    return <div className="bloqueConfiguracion-resultados-col">{c[key] === "1" ? <FiAirplay style={{ color: "darkgreen" }} /> : <FiAirplay style={{ color: "lightgrey" }} />}</div>;
                  } else {
                    return <div className="bloqueConfiguracion-resultados-col">{c[key]}</div>;
                  }
                }
              })}
              <div className="bloqueConfiguracion-resultados-col">
                <FiX style={{ color: "darkred" }} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="bloqueConfiguracion-action">
        <Input label="Nueva" id={id} suggestions={[]} explanation={""} handleInput={handleInput} />
      </div>
    </div>
  );
};

export default BloqueConfiguracion;
