import React from "react";

import "./style.css";

import slider1 from "./../../assets/images/slider-1.jpg";
import slider2 from "./../../assets/images/slider-2.jpg";
import slider3 from "./../../assets/images/slider-3.jpg";
import slider4 from "./../../assets/images/slider-4.jpg";
import { SearchComponent } from "../../components/searchComponent";

export const Searcher = ({ experiencias, tipoExperienciaSeleccionada }) => {
  const getBackgroundImage = (tipoExperienciaSeleccionada) => {
    let image = "";
    switch (parseInt(tipoExperienciaSeleccionada)) {
      case 1:
        image = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("' + slider1 + '")';
        break;
      case 2:
        image = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("' + slider2 + '")';
        break;
      case 3:
        image = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("' + slider3 + '")';
        break;
      case 4:
        image = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("' + slider4 + '")';
        break;

      default:
        image = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("' + slider1 + '")';
        break;
    }
    return image;
  };

  return (
    <div className="rowContent">
      <div className="searcherBody" style={{ backgroundImage: getBackgroundImage(tipoExperienciaSeleccionada) }}>
        <div className="contentStretch searcherContent">
          <div className="searchTitle">EXPLORA NUEVOS LUGARES</div>
          <div className="searchSubtitle">DESPIERTA TUS SENTIDOS</div>
          <SearchComponent experiencias={experiencias} />
        </div>
      </div>
    </div>
  );
};

export default Searcher;
