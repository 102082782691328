import "./style.css";

export const Badget = ({ value, icon, note, color }) => {
  return (
    <div className="badget">
      <div className="row">
        <div className="col-8 badgetQuantity">{value}</div>
        <div className="col-4 badgetIcon" style={{ color: color }}>
          {icon}
        </div>
      </div>
      <div className="row badgetNote">
        <div className="col-12">{note}</div>
      </div>
    </div>
  );
};
