import React from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import Card from "../../components/card";

export const Wishlists = () => {
  const favorites = useSelector((state) => state.aplicationConfig.wishlist);

  return (
    <div className="rowContent">
      <div className="contentStretch">
        <div className="featuredListSubtitle">Favoritos</div>
        <div style={{ display: "inline-flex", flexWrap: "wrap", width: "100%", paddingBottom: "80px" }}>
          {favorites &&
            favorites.map((e, index) => {
              return <Card key={"exp" + index} e={e} />;
            })}
        </div>
      </div>
    </div>
  );
};
